<template>
  <div id="app">
    <Header v-if="isShowHeader"></Header>
    <router-view />
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import { mapState } from "vuex";
export default {
  components: {
    Header,
  },
  computed: {
    // ...mapState(["userid"])
  },
  created() {
    // console.log(localStorage.getItem('perlist'))
    // console.log(typeof(localStorage.getItem('perlist')))
    if (this.$route.path == "/") {
      this.isShowHeader = false;
    } else {
      if (localStorage.getItem("userid")) {
        this.isShowHeader = true;
      }
    }
  },
  data() {
    return {
      isShowHeader: false,
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.path == "/") {
        this.isShowHeader = false;
      } else {
        if (localStorage.getItem("userid")) {
          this.isShowHeader = true;
        }
      }
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
  font-weight: 500;
}
.image-slot {
  width: 2vw;
  height: 2vw;
}
</style>
