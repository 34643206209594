<template>
  <div class="info-table" v-if="isshowlistbtn">
    <!-- <div class="info-operate" style="margin-bottom: 20px;">
      <el-button type="primary" v-if="isshowaddbtn" @click="addIndustry">新增</el-button>
    </div> -->
    <template>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="100"></el-table-column>
        <el-table-column label="ID" prop="id" width="100"></el-table-column>
        <el-table-column
          label="行业分类"
          prop="name"
        ></el-table-column>
        <el-table-column align="center" v-if="isshoweditbtn || isshowdelbtn">
          <template slot-scope="scope">
            <el-button v-if="isshoweditbtn" type="primary" size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              v-if="isshowdelbtn"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="info-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageData.pageNum"
        @current-change="handleCurrentChange"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 新增行业分类弹出层 -->
    <el-dialog title="新增行业分类" :visible.sync="showdialog" width="30%">
      <el-form
        ref="form"
        :rules="linesrules"
        :model="industrysdata"
        label-width="80px"
      >
        <el-form-item label="行业分类" prop="name">
          <el-input v-model="industrysdata.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceldialog">取 消</el-button>
        <el-button type="primary" @click="addBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑行业分类弹出层 -->
    <el-dialog title="编辑行业分类" :visible.sync="showeditdialog" width="30%">
      <el-form
        ref="editform"
        :rules="lineseditrules"
        :model="lineeditdata"
        label-width="80px"
      >
        <el-form-item label="行业分类" prop="name">
          <el-input v-model="lineeditdata.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceleditdialog">取 消</el-button>
        <el-button type="primary" @click="addeditBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Industy",
  data() {
    return {
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
      },
      showdialog: false,
      showeditdialog: false,
      industrysdata: {
        name: "",
      },
      lineeditdata: {
        id: "",
        name: "",
      },
      linesrules: {
        name: [
          {
            required: true,
            message: "请输入行业分类",
            trigger: "blur",
          },
        ],
      },
      lineseditrules: {
        name: [
          {
            required: true,
            message: "请输入行业分类",
            trigger: "blur",
          },
        ],
      },
      isshowlistbtn: true,
      isshowaddbtn: true,
      isshoweditbtn: true,
      isshowdelbtn: true
    };
  },
  created() {},
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('行业分类查看')) == -1) {
      this.isshowlistbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('行业分类新增')) == -1) {
      this.isshowaddbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('行业分类编辑')) == -1) {
      this.isshoweditbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('行业分类删除')) == -1) {
      this.isshowdelbtn = false
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    getData() {
      this.$axios
        .get("/web/getIndustry/", { params: this.pageData })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 删除行业分类
    handleDelete(e) {
      let id = e.id;
      let industry_ids = []
      industry_ids.push(id)
      let formdata = new FormData();
      formdata.append("industry_ids", JSON.stringify(industry_ids));
      this.$confirm("是否删除此行业分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/industry_delete/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 新增行业分类
    addIndustry() {
      this.showdialog = true;
    },
    // 取消
    canceldialog() {
      this.showdialog = false;
      this.$refs.form.resetFields();
    },
    //确认新增
    addBtn() {
      let formdata = new FormData
      formdata.append('name', this.industrysdata.name)
      this.$axios({
        url: "/backend/industry_create/",
        method: "post",
        data: formdata
      })
        .then((res) => {
          if (res.data.success) {
            this.getData();
            this.showdialog = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 编辑行业分类
    handleEdit(e) {
      let id = e.id;
      this.showeditdialog = true;
      this.lineeditdata.id = id;
      this.lineeditdata.name = e.name;
    },
    // 取消编辑
    canceleditdialog() {
      this.showeditdialog = false;
      this.$refs.editform.resetFields();
    },
    // 确认编辑
    addeditBtn() {
      let formdata = new FormData
      formdata.append('industry_id', this.lineeditdata.id)
      formdata.append('name', this.lineeditdata.name)
      this.$axios({
        url: "/backend/industry_change/",
        method: "post",
        data: formdata
      })
        .then((res) => {
          if (res.data.success) {
            this.getData();
            this.showeditdialog = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
