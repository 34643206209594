<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/adminClub' }" class="breadcrumb-text"
            >社团管理</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >新增社团</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="data-info">
        <div class="info-operate">
          <el-button type="primary" @click="submitUpload">保存</el-button>
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
        <el-card style="margin-top: 20px">
          <div
            style="
              font-size: 20px;
              font-weight: 700;
              color: #2658ea;
              text-align: left;
            "
          >
            新增社团
          </div>
          <!-- 背景 -->
          <el-row :gutter="6" style="margin-top: 20px">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">logo</div>
                <div>
                  <el-upload
                    style="display: flex; align-items: center"
                    action="/web/upload"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :class="uploadDisabled ? 'isshowimg' : ''"
                    :limit="1"
                    :on-change="handleChange"
                    :http-request="httpRequest"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="imageUrl" alt="" />
                  </el-dialog>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">名称</div>
                <el-input v-model="name"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">简称<span style="color:#c9c9c9">（用于页面显示）</span></div>
                <el-input v-model="shortname"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">排序</div>
                <el-input v-model="sort"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">备注</div>
                <el-input
                  :autosize="{ minRows: 3 }"
                  type="textarea"
                  v-model="content"
                ></el-input>
              </div>
            </el-col>
          </el-row>
          
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddAdminClub",
  components: {
    
  },
  data() {
    return {
      data: {},
      name: '',
      shortname: '',
      content: '',
      // 本地图片地址
      imageUrl: "",
      severurl: "", // 服务器图片地址
      dialogVisible: false,
      uploadDisabled: false,
      sort: 0,
    };
  },
  created() {

  },
  mounted() {},
  methods: {

    // 图片上传
    httpRequest(item) {
      const fd = new FormData();
      fd.set("file", item.file);
      this.$axios({
        url: "/web/upload/",
        method: "post",
        data: fd,
      })
        .then((res) => {
          if (res.data.success) {
            this.severurl = res.data.imgurl;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 图片更换
    handleChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (fileList.length >= 1) {
        this.uploadDisabled = true;
      }
    },

    // 图片删除
    handleRemove(file, fileList) {
      this.uploadDisabled = false;
      this.imageUrl = "";
      this.severurl = "";
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },

    // 保存 新增社团
    submitUpload() {
      let formdata = new FormData
      // 手动上传 文件列表
      if (!this.name) {
        this.$message.error("请输入名称");
        return false;
      }
      if (!this.shortname) {
        this.$message.error("请输入简称");
        return false;
      }
      if (this.sort && !Number(this.sort)) {
        this.$message.error("请输入正确的排序");
        return false;
      }
      formdata.set("logo", this.severurl);
      formdata.set("name", this.name);
      formdata.set("shortName", this.shortname);
      formdata.set("remark", this.content);
      formdata.set("sort", this.sort);
      this.$axios({
        url: "/backend/club_create/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("保存成功");
            this.$router.push({
              path: "/adminClub",
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.isshowimg /deep/ .el-upload {
  display: none;
}

// 背景框大小
/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  margin: 0 10px 0 0;
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.data-info {
  margin-top: 30px;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-card {
  // height: 70vh;
}

.el-row {
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.el-col-6 {
  margin-bottom: 20px;
}

.bg-purple {
  background: #fff;
  border: 1px solid #c9c9c9;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

// .el-input /deep/ .el-input__inner {
//   border: none;
// }

.el-input.is-disabled /deep/ .el-input__inner {
  background: #fff;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.info-breadcrumb{
  margin-top: 10px;
}

</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>