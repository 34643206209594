import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './api/https'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../public/UEditor/ueditor.config.js'
import '../public/UEditor/ueditor.all.min.js'
import '../public/UEditor/lang/zh-cn/zh-cn.js'
import '../public/UEditor/ueditor.parse.min.js'

axios.defaults.widthCredentials = true;

Vue.prototype.$axios = axios;

Vue.use(Element);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
