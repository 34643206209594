<template>
  <div class="info-table" v-if="isshowlistbtn">
    <template>
      <!-- <div class="info-operate" style="margin-bottom: 20px">
        <el-button type="primary" v-if="isshowaddbtn" @click="addLiyouMan">新增</el-button>
      </div> -->
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="100"></el-table-column>
        <el-table-column label="ID" prop="id" width="100"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="颜色">
          <template slot-scope="scope">
            <el-button :style="miStatusColor(scope.row.color)"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="封面">
          <template slot-scope="scope" v-if="scope.row.cover">
            <el-image
              style="height: 70px; width:70px;"
              :src="imgurl + scope.row.cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" v-if="isshoweditbtn || isshowdelbtn">
          <template slot-scope="scope">
            <el-button v-if="isshoweditbtn" type="primary" size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              v-if="isshowdelbtn"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="info-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageData.pageNum"
        @current-change="handleCurrentChange"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 新增梨友分类弹出层 -->
    <el-dialog title="新增梨友分类" :visible.sync="showdialog" width="30%">
      <el-form
        ref="form"
        :rules="linesrules"
        :model="industrysdata"
        label-width="80px"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="industrysdata.name"></el-input>
        </el-form-item>
        <el-form-item label="颜色" prop="color">
          <el-color-picker v-model="industrysdata.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="封面" prop="cover">
          <el-upload
            ref="uploadimgs"
            style="display: flex; align-items: center"
            action="/web/upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :class="uploadDisabled ? 'isshowimg' : ''"
            :limit="1"
            :on-change="handleChange"
            :http-request="httpRequest"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img width="100%" :src="industrysdata.cover" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceldialog">取 消</el-button>
        <el-button type="primary" @click="addBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑行业分类弹出层 -->
    <el-dialog title="编辑梨友分类" :visible.sync="showeditdialog" width="30%">
      <el-form
        ref="editform"
        :rules="lineseditrules"
        :model="lineeditdata"
        label-width="80px"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="lineeditdata.name"></el-input>
        </el-form-item>
        <el-form-item label="颜色" prop="color">
          <el-color-picker v-model="lineeditdata.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="封面">
          <el-upload
            style="display: flex; align-items: center"
            action="/web/upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :class="uploadEditDisabled ? 'isshowimg' : ''"
            :limit="1"
            :on-change="handleEditChange"
            :http-request="httpRequest"
            :file-list="lineeditdata.oneimgList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img width="100%" :src="lineeditdata.cover" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceleditdialog">取 消</el-button>
        <el-button type="primary" @click="addeditBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LiyouMan",
  props: {},
  data() {
    return {
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
      },
      showdialog: false,
      showeditdialog: false,
      industrysdata: {  // 新增梨友分类
        name: "",
        color: "",
        cover: '',
        severurl: '',
      },
      lineeditdata: {
        id: "",
        name: "",
        color: "",
        cover: '',
        severurl: '',
      },
      linesrules: {
        name: [
          {
            required: true,
            message: "请输入梨友分类",
            trigger: "blur",
          },
        ]
      },
      lineseditrules: {
        name: [
          {
            required: true,
            message: "请输入梨友分类",
            trigger: "blur",
          },
        ],
      },
      uploadDisabled: false,
      imageUrl: '',
      severurl: '',
      dialogVisible: false, // 预览弹窗
      uploadEditDisabled: false,
      isshowlistbtn: true,
      isshowaddbtn: true,
      isshoweditbtn: true,
      isshowdelbtn: true
    };
  },
  computed: {
    ...mapState(["imgurl"]),
    miStatusColor() {
      return (val) => {
        return {
          background: val,
          cursor: "auto",
        };
      };
    },
  },

  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('梨友分类查看')) == -1) {
      this.isshowlistbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('梨友分类新增')) == -1) {
      this.isshowaddbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('梨友分类编辑')) == -1) {
      this.isshoweditbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('梨友分类删除')) == -1) {
      this.isshowdelbtn = false
    }
  },

  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    getData() {
      this.$axios
        .get("/web/getLiyouClassify/", { params: this.pageData })
        .then((res) => {
          if (res.data.success) {
            (this.tableData = res.data.data), (this.totalNum = res.data.total);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },


    // 新增梨友分类
    addLiyouMan() {
      this.showdialog = true;
    },

    // 取消
    canceldialog() {
      this.showdialog = false;
      this.$refs.form.resetFields();
      this.$refs.uploadimgs.clearFiles()
      this.uploadDisabled = false
    },

    // 图片更换
    handleChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.industrysdata.cover = URL.createObjectURL(file.raw);
      if (fileList.length >= 1) {
        this.uploadDisabled = true;
      }
    },

    // 编辑图片更换
    handleEditChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.industrysdata.cover = URL.createObjectURL(file.raw);
      if (fileList.length >= 1) {
        this.uploadEditDisabled = true;
      }
    },

    // 图片上传
    httpRequest(item) {
      // 自定义文件上传
      // var up = new File([item.file], item.file.name, {
      //   type: item.file.type
      // });
      const fd = new FormData();
      fd.set("file", item.file);
      this.$axios({
        url: "/web/upload/",
        method: "post",
        data: fd,
      })
        .then((res) => {
          if (res.data.success) {
            this.severurl = res.data.imgurl;
            this.industrysdata.severurl = res.data.imgurl
            this.lineeditdata.severurl = res.data.imgurl
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 图片删除
    handleRemove(file, fileList) {
      this.uploadDisabled = false;
      this.uploadEditDisabled = false;
      this.imageUrl = "";
      this.industrysdata.severurl = "";
      this.industrysdata.cover = ''
      this.lineeditdata.cover = ''
      this.lineeditdata.severurl = ''
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },

    // 删除梨友分类
    handleDelete(e) {
      let id = e.id;
      let classify_ids = [];
      classify_ids.push(id);
      let formdata = new FormData();
      formdata.append("classify_ids", JSON.stringify(classify_ids));
      this.$confirm("是否删除此梨友分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/classify_delete/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //确认新增
    addBtn() {
      let formdata = new FormData();
      if(!this.industrysdata.severurl) {
        this.$message.error('请选择封面！')
        return false
      }
      formdata.append("name", this.industrysdata.name);
      formdata.append("color", this.industrysdata.color);
      formdata.append("cover", this.industrysdata.severurl);
      this.$axios({
        url: "/backend/classify_create/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.getData();
            this.showdialog = false;
            this.$refs.uploadimgs.clearFiles()
            this.$refs.form.resetFields();
            this.uploadDisabled = false
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 编辑梨友分类
    handleEdit(e) {
      let id = e.id;
      this.showeditdialog = true;
      this.lineeditdata.id = id;
      this.lineeditdata.name = e.name;
      this.lineeditdata.color = e.color;
      if(e.cover && e.cover != null) {
        this.lineeditdata.cover = this.imgurl + e.cover;
        this.lineeditdata.severurl = e.cover
        let oneimg = [{ url: this.imgurl + e.cover }];
        this.lineeditdata.oneimgList = oneimg;
        this.uploadEditDisabled = true;
      } else {
        this.lineeditdata.oneimgList = [];
        this.uploadEditDisabled = false;
      }
    },
    // 取消编辑
    canceleditdialog() {
      this.showeditdialog = false;
      this.$refs.editform.resetFields();
    },
    // 确认编辑
    addeditBtn() {
      if(!this.lineeditdata.severurl) {
        this.$message.error('请选择封面！')
        return false
      }
      let formdata = new FormData();
      formdata.append("classify_id", this.lineeditdata.id);
      formdata.append("name", this.lineeditdata.name);
      formdata.append("color", this.lineeditdata.color);
      formdata.append("cover", this.lineeditdata.severurl);
      this.$axios({
        url: "/backend/classify_change/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.getData();
            this.showeditdialog = false;
            this.uploadDisabled = false
            this.uploadEditDisabled = false
            this.lineeditdata = {
                id: "",
                name: "",
                color: "",
                cover: '',
                severurl: '',
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>

.isshowimg /deep/ .el-upload {
  display: none;
}
.info-table /deep/ .el-image__inner {
  object-fit: cover;
}

</style>
