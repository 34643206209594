<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >业务介绍</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="flexbox">
        <div class="search_box">
          <el-input
            placeholder="请输入标题"
            @clear="clearPageGetData"
            v-model="pageData.search"
            clearable
          >
          </el-input>
          <el-button
            style="margin-left: 20px"
            type="primary"
            @click="clearPageGetData"
            icon="el-icon-search"
            >搜索</el-button
          >
        </div>
        <div class="info-operate">
          <el-button type="primary" @click="addBusiness">新增</el-button>
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
      </div>

      <div class="info-tab">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="100"></el-table-column>
              <el-table-column label="ID" width="100" prop="id">
              </el-table-column>
              <el-table-column label="标题" prop="title"></el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image
                    style="height: 80px;width:100%;"
                    :src="imgurl + scope.row.cover"
                  >
                  <div slot="error" class="image-slot" style="width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                      <img src="../assets/image/defaultImg.png" alt="" style="object-fit:cover;width:50%;height:50%;opacity:0.8;">
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="发布时间"
                prop="create_time"
              ></el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="businessinfo(scope.row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.pageNum"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Business",
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
        search: "",
      },
    };
  },
  created() {
    this.pageData.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    // 清除page重载列表
    clearPageGetData(){
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios({
        url: "/backend/personal_introduction_list/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 新增业务介绍
    addBusiness() {
      this.$router.push({
        path: "/addbusiness/",
      });
    },

    // 业务介绍详情
    businessinfo(id) {
      this.$router.push({
        path: '/businessinfo/',
        query: {
          'id': id
        }
      })
    },

    // 删除
    handleDelete(e, row) {
      let id = row.id;
      let ids = [];
      ids.push(id);
      let formdata = new FormData();
      formdata.set("ids", JSON.stringify(ids));
      this.$confirm("是否删除此业务介绍?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/personal_introduction_delete/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.$message.success("操作成功");
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
.info-breadcrumb{
  margin-top: 10px;
}

.flexbox{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
// 搜索框
.search_box {
  width: 25%;
  display: flex;
  align-items: center;
}

.info-table /deep/ .el-image__inner {
  object-fit: cover;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-image__inner {
  object-fit: cover;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
