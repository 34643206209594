import axios from 'axios'
import { Message } from 'element-ui'

axios.defaults.timeout = 60000
// axios.defaults.baseURL = 'https://lihuashu.gazrey.com/'
axios.defaults.baseURL = 'https://lhs.gazrey.com/'
// axios.defaults.baseURL = 'http://127.0.0.1:8888/'

// session验证码功能，让请求携带cookie
axios.defaults.withCredentials = true;

//(添加请求拦截器)
axios.interceptors.request.use(
    request => {
        return request
    }
)
// https://lhs.gazrey.com/

//axios.js,axios的封装，具体可以查其他资料，这里主要展示请求拦截
//请求拦截
// axios.interceptors.request.use(
//     config => {
//         console.log('config', config);
//         // 从vuex读取token的值,给请求头添加laohu-token
//         if (store.state.userToken) {
//             config.headers['user-token'] = store.state.userToken;//如果存在token，将token放入请求头
//         }
//         return config
//     },
//     err => {
//         return Promise.reject(err)
//     })



//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
    if (!res.data.success) {
        if (res.data.code == '403') {
            Message.error('未登录, 将跳转至登录页')
            setTimeout(() => {
                window.location.href = '/'
            }, 1000);
        }
        return Promise.resolve(res)
    }
    return res
}, (error) => {
    // console.log(error)
    return Promise.reject('网络异常，请稍后重试！')
})


// axios.interceptors.response.use(
//     response => {
//         if (response.status === 200) {
//             return Promise.resolve(response.data)
//         }
//     },
//     error => {
//         console.log('error:', error.response)
//         // if(error.response.status==403){
//             return Promise.resolve(error.response)
//         // }
//     }
// )


// 返回一个Promise(发送get请求)
export function fetchGet(url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}


// export default {
//     axios,
//     fetchGet,
//     fetchPost
// }

export default axios
