<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club/article?id=' + this.clubid }"
            >文章</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >文章详情</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="data-info">
        <div class="info-operate">
          <el-button type="primary" v-if="isshoweditbtn" @click="submitUpload">保存</el-button>
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
        <el-card style="margin-top: 20px">
          <div
            style="
              font-size: 20px;
              font-weight: 700;
              color: #2658ea;
              text-align: left;
            "
          >
            文章详情
          </div>
          <!-- 背景 -->
          <el-row :gutter="6" style="margin-top: 20px">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">背景</div>
                <div>
                  <el-upload
                    style="display: flex; align-items: center"
                    action="/web/upload"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :class="uploadDisabled? 'isshowimg': ''"
                    :limit="1"
                    :on-change="handleChange"
                    :http-request="httpRequest"
                    :on-exceed="imgexceed"
                    :file-list="oneimgList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="imageUrl" alt="" />
                  </el-dialog>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">标题</div>
                <el-input v-model="data.title"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">发布人</div>
                <el-select
                  style="width: 100%"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.department_id"
                    :label="item.departmentName"
                    :value="item.department_id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">开始时间 - 结束时间</div>
                <div class="block">
                  <el-date-picker
                    v-model="timevalue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </div>
            </el-col>
          </el-row>
          <vue-ueditor-wrap ref="editor" v-model="msg" :config="editorConfig" @before-init="addXiumiDialog" :destroy="true" />
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueUeditorWrap  from 'vue-ueditor-wrap'
export default {
  name: "ArticleInfo",
  computed: {
    ...mapState(['imgurl'])
  },
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      data: {},
      options: [
      ],
      title: "",
      address: "",
      enrollMaxNum: "",
      content: "",
      instructions: "",
      partid: '', // 部门id
      // 选择的部门
      value: "",
      // 本地图片地址
      imageUrl: "",
      severurl: "", // 服务器图片地址
      dialogVisible: false,
      dialogimgsVisible: false,
      // 开始时间至结束时间
      timevalue: [],
      uploadDisabled: false,
      oneimgList: [], // 单图赋值
      deletedata: [], // 删除的图片
      // [ "2021-12-30 00:00:00", "2022-01-19 00:00:00" ]
      formDate: "",
      msg: '',
      // ueditor配置
      editorConfig: {
        autoHeightEnabled: true, // 自动变高
        autoFloatEnabled: true,
        initialContent: '请输入内容',
        autoClearinitialContent: true,
        initialFrameWidth: '100%', // 初始化宽
        initialFrameHeight: 500, // 初始化高
        BaseUrl: '',
        // 上传文件接口
        // serverUrl: 'https://lihuashuadmin.gazrey.com/web/controller/',
        serverUrl: 'https://lhsv.gazrey.com/web/controller/',
        // serverUrl: '//ueditor.szcloudplus.com/cos',
        // UEditor 资源文件的存放路径 
        UEDITOR_HOME_URL: '/UEditor/',
        isshoweditbtn: true,
      }
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.clubid = this.$route.query.clubid;
    this.partid = this.$route.query.partid
    this.requestData();
  },
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('文章编辑')) == -1) {
      this.isshoweditbtn = false
    }
  },
  methods: {
    // 时间转化

    requestData() {
      this.$axios({
        url: "/backend/get_club_article/",
        methods: "get",
        params: {
          club_id: this.clubid,
          department_id: this.partid,
          article_id: this.id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
            this.options = res.data.data.dep_list;
            this.timevalue = [res.data.data.articleStartDate, res.data.data.articleendDate]
            this.value = res.data.data.department_id
            this.cuttime = res.data.data.enrollDate
            this.imageUrl = this.imgurl + res.data.data.cover
            this.severurl = res.data.data.cover
            this.msg = res.data.data.context
            let oneimg = [
              {'url': this.imgurl + res.data.data.cover},
            ]
            this.uploadDisabled = true
            this.oneimgList = oneimg
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },


    addXiumiDialog(editorId) {
      window.UE.registerUI(
        'xiumi-dialog',
        (editor, uiName) => {
          // 创建 “秀米弹窗”
          const dialog = new window.UE.ui.Dialog({
            // 注意：这是 xiumi-ue-dialog-v5.html 文件的访问链接，这个页面会通过 iframe 的方式嵌入到弹窗里
            iframeUrl: this.editorConfig.UEDITOR_HOME_URL + 'xiumi/xiumi-ue-dialog-v5.html',
            editor,
            name: uiName,
            title: '秀米图文消息助手',
            cssRules: 'width: ' + (window.innerWidth - 200) + 'px; height: ' + (window.innerHeight - 200) + 'px;',
          });

          // 添加自定义按钮用于唤起“秀米弹窗”
          const btn = new window.UE.ui.Button({
            name: 'xiumi-connect',
            title: '秀米',
            cssRules: `background-image: url('//dl.xiumi.us/connect/ue/xiumi-connect-icon.png') !important; background-size: contain;`,
            onclick() {
              dialog.render();
              dialog.open();
            },
          });

          return btn;
        },
        0 /* 指定添加到工具栏上的那个位置，默认时追加到最后 */,
        editorId /* 指定这个UI是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
      );
    },

    // 图片上传
    httpRequest(item) {
      // 自定义文件上传
      // var up = new File([item.file], item.file.name, {
      //   type: item.file.type
      // });
      const fd = new FormData();
      fd.set("file", item.file);
      this.$axios({
        url: "/web/upload/",
        method: "post",
        data: fd,
      })
        .then((res) => {
          if (res.data.success) {
            this.severurl = res.data.imgurl;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 图片更换
    handleChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (fileList.length >= 1) {
        this.uploadDisabled = true;
      }
    },

    // 图片删除
    handleRemove(file, fileList) {
      this.uploadDisabled = false;
      this.imageUrl = "";
      this.severurl = "";
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    // 选择超出
    imgexceed(files, fileList) {
      this.$message.error("背景只能上传一张");
    },

    // 保存 新增`活动
    submitUpload() {
      this.formDate = new FormData();
      if (!this.severurl) {
        this.$message.error("请选择背景图片");
        return false;
      }
      if (!this.data.title) {
        this.$message.error("请输入标题");
        return false;
      }
      if (!this.value) {
        this.$message.error("请选择发布人");
        return false;
      }
      if (!this.timevalue[0]) {
        this.$message.error("请选择开始时间-结束时间");
        return false;
      }
      this.formDate.set("article_id", this.id);
      this.formDate.set("department_id", this.partid);
      this.formDate.set("start_time ", this.timevalue[0]);
      this.formDate.set("end_time", this.timevalue[1]);
      this.formDate.set("cover", this.severurl);
      this.formDate.set("title", this.data.title);
      this.formDate.set("department_id", this.value);
      this.formDate.set("content", this.msg);
      this.$axios({
        url: "/backend/change_club_article/",
        method: "post",
        data: this.formDate,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("保存成功");
            this.$router.push({
              path: "/club/article",
              query: {
                id: this.clubid,
              },
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
  .volunteers-info {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
  .volun-container {
    margin-top: 20px;
  }
  .volun-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin-right: 10px;
      // width: 15%;
      padding: 5px 10px;
      height: 40px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 10px 0px rgba(148, 158, 229, 0.1);
      margin-bottom: 10px;
    }
  }
}

.isshowimg /deep/ .el-upload {
  display: none;
}

// 背景框大小
/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  margin: 0 10px 0 0;
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.data-info {
  margin-top: 30px;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-card {
  // height: 70vh;
}

.el-row {
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.el-col-6 {
  margin-bottom: 20px;
}

.bg-purple {
  background: #fff;
  border: 1px solid #c9c9c9;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

// .el-input /deep/ .el-input__inner {
//   border: none;
// }

.el-input.is-disabled /deep/ .el-input__inner {
  background: #fff;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>