<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/adminClub/' }"
            >社团管理</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >查看申请</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <!-- <div class="search_box">
        <el-input placeholder="请输入姓名" @clear="clearPageGetData" v-model="pageData.search" clearable>
        </el-input>
        <el-button style="margin-left: 20px" type="primary" @click="clearPageGetData" icon="el-icon-search">搜索</el-button>
      </div> -->
      <div class="info-tab">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="100"></el-table-column>
              <el-table-column label="ID" prop="id"></el-table-column>
              <el-table-column
                label="创建时间"
                prop="create_date"
              ></el-table-column>
              <el-table-column
                label="额度（小时）"
                prop="num"
              ></el-table-column>
              <el-table-column label="理由" prop="content"></el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.genre == 1">
                    {{ scope.row.status == 0 ? "已通过" : "未通过" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="类型（分配/申请）">
                <template slot-scope="scope">
                  <div>{{ scope.row.genre == 0 ? "分配" : "申请" }}</div>
                </template>
              </el-table-column>
              <el-table-column v-if="isshowbtn" align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.genre == 1 && scope.row.status == null"
                    size="mini"
                    type="success"
                    @click="handlePass(scope.row)"
                    >通过</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.pageNum"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AdminLimitCheck",
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
        search: "",
      },
      isshowbtn: true
    };
  },
  created() {
    this.clubid = this.$route.query.id;
    this.pageData.id = this.$route.query.id;
    this.getData();
  },
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('额度审核')) == -1) {
      this.isshowbtn = false
    }
  },
  computed: {
    ...mapState(["imgurl"]),
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    // 清除page重载列表
    clearPageGetData(){
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios({
        url: "/web/getClubLimit/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 通过申请
    handlePass(row) {
      let id = row.id;
      let formdata = new FormData();
      formdata.set("id", id);
      this.$confirm("是否通过此申请?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/lines_oper/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消通过",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

// 搜索框
.search_box {
  margin-top: 40px;
  width: 25%;
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
