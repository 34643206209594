<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/needs' }"
            >梨友需求管理</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >需求详情</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="data-info">
        <div class="info-operate">
          <!-- <el-button type="primary" @click="submitUpload">保存</el-button> -->
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
        <el-card style="margin-top: 20px">
          <div
            style="
              font-size: 20px;
              font-weight: 700;
              color: #2658ea;
              text-align: left;
            "
          >
            需求详情
          </div>
          <!-- 背景 -->
          <el-row :gutter="6" style="margin-top: 20px">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">背景</div>
                <div>
                  <el-upload
                    style="display: flex; align-items: center"
                    action="/web/upload"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :class="uploadDisabled ? 'isshowimg' : ''"
                    :limit="1"
                    :on-change="handleChange"
                    :http-request="httpRequest"
                    :on-exceed="imgexceed"
                    :file-list="oneimgList"
                    :disabled="true"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" >
                    <img width="100%" :src="imageUrl" alt=""/>
                  </el-dialog>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">标题</div>
                <el-input v-model="data.title" :disabled="true"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">发布类型</div>
                <el-input v-model="data.classify_name" :disabled="true"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">所属行业</div>
                <el-input v-model="data.industry_name" :disabled="true"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">发布人</div>
                <el-input v-model="data.author" :disabled="true"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">浏览量</div>
                <el-input v-model="data.seenum" :disabled="true"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">发布时间</div>
                <el-input v-model="data.releaseDate" :disabled="true"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-top: 20px">
            <el-col :span="12">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">描述</div>
                <el-input
                  :autosize="{ minRows: 3 }"
                  type="textarea"
                  v-model="data.description"
                  :disabled="true"
                ></el-input>
              </div>
            </el-col>
          </el-row>
          <div
            style="
              display: flex;
              margin-top: 30px;
              flex-direction: column;
              align-items: flex-start;
            "
          >
            <div style="margin-bottom: 10px">内容图片：</div>
            <div>
              <!-- 取消文件自动上传 :on-success="handleSuccess  :on-error="handleSuccess"" -->
              <el-upload
                style="display: flex; align-items: center"
                action="/web/upload"
                list-type="picture-card"
                :auto-upload="false"
                :on-remove="handleimgsRemove"
                :on-preview="handleimgsPictureCardPreview"
                :multiple="true"
                :http-request="uploadFile"
                ref="upload"
                :file-list="manyfileList"
                :disabled="true"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogimgsVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NeedsInfo",
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      data: {},
      options: [],
      title: "",
      address: "",
      enrollMaxNum: "",
      content: "",
      instructions: "",
      // 本地图片地址
      imageUrl: "",
      severurl: "", // 服务器图片地址
      dialogImageUrl: "", // 多图地址
      dialogVisible: false,
      dialogimgsVisible: false,
      oneimgList: [], // 单图赋值
      manyfileList: [], // 多图赋值
      deletedata: [], // 删除的图片
      uploadDisabled: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.clubid = this.$route.query.clubid;
    this.requestData();
  },
  mounted() {
  },
  methods: {
    // 时间转化

    requestData() {
      this.$axios({
        url: "/backend/get_needs/",
        methods: "get",
        params: {
          id: this.id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
            if (res.data.data.cover) {
              this.imageUrl = this.imgurl + res.data.data.cover;
              this.severurl = res.data.data.cover;
              let oneimg = [{ url: this.imgurl + res.data.data.cover }];
              this.uploadDisabled = true;
              this.oneimgList = oneimg;
            }

            let contentlistimg = [];
            res.data.data.contentImage.forEach((item, index) => {
              let contentdict = {};
              if (item && item != null) {
                res.data.data.contentImage[index] = this.imgurl + item;
                contentdict = {
                  url: res.data.data.contentImage[index],
                  deleteurl: item,
                };
                contentlistimg.push(contentdict);
              }
            });
            this.manyfileList = contentlistimg;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 图片上传
    httpRequest(item) {
      // 自定义文件上传
      // var up = new File([item.file], item.file.name, {
      //   type: item.file.type
      // });
      const fd = new FormData();
      fd.set("file", item.file);
      this.$axios({
        url: "/web/upload/",
        method: "post",
        data: fd,
      })
        .then((res) => {
          if (res.data.success) {
            this.severurl = res.data.imgurl;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 图片更换
    handleChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (fileList.length >= 1) {
        this.uploadDisabled = true;
      }
    },

    // 图片删除
    handleRemove(file, fileList) {
      this.uploadDisabled = false;
      this.imageUrl = "";
      this.severurl = "";
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    // 选择超出
    imgexceed(files, fileList) {
      this.$message.error("背景只能上传一张");
    },

    // 多图图片预览
    handleimgsPictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgsVisible = true;
    },

    // 多图 上传完毕
    // handleSuccess(response, file, fileList) {
    //   console.log(response);
    //   this.$alert("相似度为" + response["pair_verify_similarity"], "经过对比", {
    //     confirmButtonText: "确定",
    //     callback: (action) => {},
    //   });
    // },

    // 多图删除
    handleimgsRemove(file, fileList) {
      if (file.deleteurl) {
        this.deletedata.push(file.deleteurl);
      }
    },

    // 多图上传
    uploadFile(file) {
      this.formDate.append("images", file.file);
    },

    // 保存 新增活动
    submitUpload1() {
      this.formDate = new FormData();
      // 手动上传 文件列表
      this.$refs.upload.submit();
      if (!this.severurl) {
        this.$message.error("请选择背景图片");
        return false;
      }
      if (!this.data.title) {
        this.$message.error("请输入标题");
        return false;
      }
      if (!this.value) {
        this.$message.error("请选择发布人");
        return false;
      }
      if (!this.timevalue[0]) {
        this.$message.error("请选择开始时间-结束时间");
        return false;
      }
      if (!this.data.address) {
        this.$message.error("请输入活动地点");
        return false;
      }
      if (!this.data.enrollMaxNum) {
        this.$message.error("请输入报名最大人数");
        return false;
      }
      if (!parseInt(this.data.enrollMaxNum)) {
        this.$message.error("人数必须为数值");
        return false;
      }
      if (!this.cuttime) {
        this.$message.error("请输入截止日期");
        return false;
      }
      this.formDate.set("id", this.id);
      this.formDate.set("address", this.data.address);
      this.formDate.set("club_id", this.clubid);
      this.formDate.set("start_time ", this.timevalue[0]);
      this.formDate.set("end_time", this.timevalue[1]);
      this.formDate.set("cover", this.severurl);
      this.formDate.set("title", this.data.title);
      this.formDate.set("jiezhi_time", this.cuttime);
      this.formDate.set("enrollMaxNum", this.data.enrollMaxNum);
      this.formDate.set("dep", this.value);
      this.formDate.set("content", this.data.content);
      this.formDate.set("instructions", this.data.instructions);
      this.formDate.set("data", JSON.stringify(this.volunterdata));
      this.formDate.set("del_list_file", this.deletedata);
      this.formDate.set("del_enroll_ids", this.deletevoluntdata);
      this.$axios({
        url: "/backend/change_club_activity/",
        method: "post",
        data: this.formDate,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("保存成功");
            this.$router.push({
              path: "/club/activity",
              query: {
                id: this.clubid,
              },
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
  .volunteers-info {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
  .volun-container {
    margin-top: 20px;
  }
  .volun-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin-right: 10px;
      // width: 15%;
      padding: 5px 10px;
      height: 40px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 10px 0px rgba(148, 158, 229, 0.1);
      margin-bottom: 10px;
    }
  }
}

.isshowimg /deep/ .el-upload {
  display: none;
}

// 背景框大小
/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  margin: 0 10px 0 0;
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.data-info {
  margin-top: 30px;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-card {
  // height: 70vh;
}

.el-row {
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.el-col-6 {
  margin-bottom: 20px;
}

.bg-purple {
  background: #fff;
  border: 1px solid #c9c9c9;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

// .el-input /deep/ .el-input__inner {
//   border: none;
// }

.el-input.is-disabled /deep/ .el-input__inner {
  background: #fff;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.info-breadcrumb{
  margin-top: 10px;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>