import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userid: '',
    username: '',
    userimg: '',
    // imgurl: 'https://lihuashu.gazrey.com/media/',
    imgurl: 'https://lhs.gazrey.com/media/',
    // imgurl: 'http://127.0.0.1:8888/media/',
    apllynum: 0, // 社团申请数量
  }, 
  mutations: {
    // 保存用户id
    setUserid(state, value){
      state.userid = value;
      // sessionStorage.setItem("userid", value);
      localStorage.setItem("userid", value);
    },
    // 用户姓名
    setUsername(state, value) {
      state.username = value
      localStorage.setItem("username", value);
    },
    // 用户头像
    setUserimg(state, value) {
      state.userimg = value
      localStorage.setItem("userimg", value);
    },
    // 社团申请数量
    setapllynum(state, value){
      state.apllynum = value;
    },

  },
  actions: {
  },
  modules: {
  }
})
