<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >用户管理</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="info-tab">
        <template>
          <el-tabs
            class="tab-self"
            v-model="activeName"
            type="card"
            @tab-click="handleClick"
          >
            <el-tab-pane label="平台管理员" name="manage">
              <Manage ref="manage"></Manage>
            </el-tab-pane>
            <el-tab-pane label="普通用户" name="user">
              <User ref="user"></User>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Manage from '@/components/Manage'
import User from '@/components/User'
export default {
  name: "AdminUser",
  props: {},
  components: {
    Manage,
    User,
  },
  data() {
    return {
      activeName: "manage",
    };
  },
  created() {},
  mounted() {
    this.tableLocal();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;

      if (tab.name == 'manage') {
        if(String(localStorage.getItem('perlist').indexOf('平台管理员查看')) == -1) {
          this.$message.error('无权限查看')
          return false
        }
      }
      if (tab.name == 'user') {
        if(String(localStorage.getItem('perlist').indexOf('普通用户查看')) == -1) {
          this.$message.error('无权限查看')
          return false
        }
      }

      sessionStorage.setItem('adminuser', tab.name)
      let that = this;
      setTimeout(() => {
        that.onData();
      }, 50);
    },
    onData() {
      this.$refs[this.activeName].getData();
    },
    // 页面刷新
    tableLocal() {
      if (sessionStorage.getItem("adminuser") != null) {
        this.activeName = sessionStorage.getItem("adminuser");
        this.onData();
      } else {
        this.onData()
      }
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;
    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 20px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
.info-breadcrumb{
  margin-top: 10px;
}


</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>