<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >申请消息</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <!--  @scroll="scrollHandle" -->
      <div class="con" ref="box">
        <div class="infinite-list" v-infinite-scroll="load" ref="scroll" infinite-scroll-distance="1">
          <div class="infinite-list-item" v-for="(item, index) in newslist" :key="index">
            <div
              class="item no"
              :data-state="item.state"
              :data-id="item.id"
              :data-index="index"
            >
              <div class="top">
                <div class="left">
                  <img :src="imgurl + item.img" alt="" />
                  <div>{{item.name}} 申请加入社团 {{item.status === 2? '——已通过': item.status === 1? '——已拒绝': ''}}</div>
                </div>
                <div class="make" v-if="!item.status">
                  <div
                    class="newsitem"
                    :data-messageid="item.id"
                    :data-index="index"
                    data-status="1"
                    @click="operateBtn"
                  >
                    [拒绝]
                  </div>
                  <div
                    class="newsitem"
                    :data-messageid="item.id"
                    :data-index="index"
                    data-status="2"
                    @click="operateBtn"
                  >
                    [同意]
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="news">
                  <div class="newsitem" v-show="item.name">
                    姓名：{{ item.name }}
                  </div>
                  <div class="newsitem" v-show="item.contactWay">
                    电话：{{ item.contactWay }}
                  </div>
                  <div class="newsitem" v-show="item.create_time">
                    时间：{{ item.create_time }}
                  </div>
                  <div class="newsitem" v-show="item.note">
                    备注：{{ item.note }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="msg">{{ msg }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ApplyInfo",
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      clubid: "",
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
      },
      newslist: [],
      msg: ''
    };
  },
  created() {
    this.clubid = this.$route.query.id;
    this.pageData.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      this.$axios({
        url: "/web/applylist/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            for (let i = 0; i < res.data.data.length; i++) {
              const element = res.data.data[i];
              this.newslist.push(element);
            }
            // this.newslist = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    load(){
        console.log('load了');
        this.pageData.currentPage++;
        this.getData();
    },

    // 删除
    handleDelete(e, row) {
      let userid = row.id;
      let user_ids = [];
      user_ids.push(userid);

      this.$confirm("是否删除此人员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/delete_club_user/",
            method: "get",
            params: {
              user_ids: JSON.stringify(user_ids),
              club_id: this.clubid,
            },
          })
            .then((res) => {
              if (res.data.success) {
                this.$message.success("删除成功");
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 通过拒绝
    operateBtn(e) {
      let status = e.currentTarget.dataset.status;
      let index = e.currentTarget.dataset.index;
      let messageid = e.currentTarget.dataset.messageid;
      let formdata = new FormData
      formdata.set('status', status)
      formdata.set('messageid', messageid)
      this.$axios({
        url: '/web/applyClub/',
        method: 'post',
        data: formdata
      }).then((res) => {
        if(res.data.success) {
          if (status == 2) {
            this.newslist[index].status = 2
          } else {
            this.newslist[index].status = 1
          }
        } else {
          this.$message.error(res.data.error)
        }
      }).catch((error)=> {
        this.$message.error(error)
      })
    },

  },

};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }

  .con {
    margin-top: 30px;
    background: #ffffff;
    height: calc(100vh - 13vw);
    box-sizing: border-box;
    // padding: 0.5vw 1.5vw;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
    background-color: #fff;
    padding: 0 1.3vw;
    &::-webkit-scrollbar {
      width: 0.3vw; /*宽对应竖滚动条的尺寸宽度*/
      height: 0.3vw; /*高对应横滚动条的尺寸高度*/
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: transparent; /* 竖直滚动块的颜色 */
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent; /* 轨道的背景颜色 */
    }
    .item {
      padding: 1vw 0;
      border-bottom: 1px solid #ededed;
      font-size: 0.8vw;
      cursor: pointer;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 2vw;
            height: 2vw;
            border-radius: 50%;
            margin-right: 0.7vw;
          }
        }
        .make {
          display: flex;
          align-items: center;
          div {
            margin-right: 1.5vw;
            color: #585ebc;
            cursor: pointer;
            &:nth-child(1) {
              color: #717479;
            }
          }
        }
      }
      .bottom {
        margin-top: 1vw;
        background: #f5f5f5;
        border-radius: 0.7vw;
        box-sizing: border-box;
        font-size: 0.8vw;
        color: #585ebc;
        padding: 1vw;
        display: flex;
        justify-content: space-between;
        .news {
          display: flex;
          flex-wrap: wrap;
          width: 12vw;
          .newsitem {
            display: flex;
            width: 100%;
            margin-bottom: 1vw;
          }
          .newsitem:last-child {
            margin-bottom: 0;
          }
          .bck {
            width: 7vw;
            height: 4vw;
          }
          &.self {
            .bck {
              width: 7vw;
              height: 10vw;
            }
          }
        }
      }
    }
    .item.no {
      color: #777;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
