<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club/user?id=' + this.clubid }"
            >社团人员</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >社团人员信息</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="data-info">
        <div class="info-operate">
          <el-button type="primary" v-if="isshowupdatebtn" @click="UpdateUser"
            >保存</el-button
          >
        </div>
        <el-card style="margin-top: 20px">
          <div
            style="
              font-size: 20px;
              font-weight: 700;
              color: #2658ea;
              text-align: left;
            "
          >
            社团人员信息
          </div>
          <el-row :gutter="80" style="margin-top: 20px">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">姓名</div>
                <el-input :disabled="true" v-model="data.username"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">性别</div>
                <el-input :disabled="true" v-model="data.gender"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">入学年份</div>
                <el-select
                  style="width: 100%"
                  v-model="gradename"
                  placeholder="请选择"
                  @change="partChange"
                  :disabled="permissionData.length > 0 ? false : true"
                >
                  <el-option
                    v-for="(item, index) in grades"
                    :key="index"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div> </el-col
            ><el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">专业</div>
                <el-select
                  style="width: 100%"
                  v-model="clas"
                  placeholder="请选择"
                  @change="partChange"
                  :disabled="permissionData.length > 0 ? false : true"
                >
                  <el-option
                    v-for="(item, index) in classes"
                    :key="index"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <!-- <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">年级-班级</div>
                <el-input :disabled="true" v-model="data.gradeclass"></el-input>
              </div>
            </el-col> -->
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">联系方式</div>
                <el-input :disabled="true" v-model="data.phoneNum"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">邮箱</div>
                <el-input :disabled="true" v-model="data.email"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">职务</div>
                <el-select
                  style="width: 100%"
                  v-model="jobvalue"
                  placeholder="请选择"
                  @change="partChange"
                >
                  <el-option
                    v-for="item in joboptions"
                    :key="item.position_id"
                    :label="item.position_name"
                    :value="item.position_id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">部门</div>
                <el-select
                  style="width: 100%"
                  v-model="value"
                  placeholder="请选择"
                  @change="partChange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.department_id"
                    :label="item.name"
                    :value="item.department_id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <div class="per-container" v-if="permissionData.length > 0">
            <div v-if="club_admin">社团权限</div>
            <div v-else>部门权限</div>
            <div class="choose_box">
              <div>
                <el-checkbox
                  @change="allCheck"
                  v-model="isallCheck"
                  :indeterminate="isIndeterminate"
                  label="全选"
                ></el-checkbox>
              </div>
              <!-- <div style="margin-left: 10px">
                <el-checkbox @change="noallCheck" label="非全选"></el-checkbox>
              </div> -->
            </div>

            <div v-for="(item, index) in permissionData" :key="index">
              <el-checkbox
                style="margin-top: 20px; display: flex; align-items: flex-start"
                :indeterminate="item.isIndeterminate"
                v-model="item.isCheck"
                @change="handleCheckAllChange(item.isCheck, index)"
                >{{ item.name }}
              </el-checkbox>
              <div style="margin: 15px 0"></div>
              <el-checkbox-group
                v-model="item.checkedData"
                @change="handleCheckedCitiesChange(item.checkedData, index)"
              >
                <el-checkbox
                  v-for="(pitem, index) in item.perlist"
                  :label="pitem.id"
                  :key="index"
                >
                  {{ pitem.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>

            <!-- <el-checkbox
              style="margin-top: 20px"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >社团活动管理</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                city
              }}</el-checkbox>
            </el-checkbox-group> -->
          </div>
          <div style="display: flex; margin-top: 30px; align-items: center">
            <div>社团称号：</div>
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ 社团称号</el-button
            >
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClubUserInfo",
  data() {
    return {
      data: {},
      userid: "",
      name: "",
      grade: "",
      classname: "",
      options: [
        {
          department_id: 0,
          name: "无",
        },
      ],
      value: "",
      grades: [],
      gradename: "",
      classes: [],
      clas: "",
      jobvalue: "",
      joboptions: [],
      // 标签列表
      dynamicTags: [],
      inputVisible: false,
      // 输入的标签
      inputValue: "",
      checkAll: false,
      permissionData: [], // 权限
      isallCheck: false, // 最外层全选
      isIndeterminate: true, // 最外层全选
      club_admin: false,
      department_admin: false,
      is_self: false,
      isshowupdatebtn: true,
    };
  },
  created() {
    this.userid = this.$route.query.id;
    this.clubid = this.$route.query.clubid;
    this.requestData();
    // this.requestPermission()
    this.getInfor();
  },
  mounted() {
    if (String(localStorage.getItem("perlist").indexOf("人员编辑")) == -1) {
      this.isshowupdatebtn = false;
    }
  },
  methods: {
    getInfor() {
      this.$axios({
        url: "/backend/user_grade/",
        methods: "get",
      })
        .then((res) => {
          if (res.data.success) {
            for (let i = 0; i < res.data.list.length; i++) {
              const element = res.data.list[i];
              this.grades.push({ name: element.gradename });
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });

      this.$axios({
        url: "/backend/user_class/",
        methods: "get",
      })
        .then((res) => {
          if (res.data.success) {
            for (let i = 0; i < res.data.list.length; i++) {
              const element = res.data.list[i];
              this.classes.push({ name: element.classname });
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    requestData() {
      this.$axios({
        url: "/backend/get_club_user/",
        methods: "get",
        params: {
          user_id: this.userid,
          club_id: this.clubid,
        },
      })
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.gender) {
              res.data.data.gender = "男";
            } else {
              res.data.data.gender = "女";
            }
            this.data = res.data.data;
            this.value = res.data.data.departmentId;
            this.gradename = res.data.data.grade;
            this.clas = res.data.data.className;
            this.options = this.options.concat(res.data.data.department_all);
            this.jobvalue = res.data.data.position_id;
            this.joboptions = res.data.data.positions;

            this.dynamicTags = res.data.data.tags;

            this.club_admin = res.data.data.club_admin;
            this.department_admin = res.data.data.department_admin;
            this.is_self = res.data.data.is_self;

            this.requestPermission(
              res.data.data.club_admin,
              res.data.data.department_admin,
              res.data.data.club_id,
              res.data.data.departmentId
            );

            // if (res.data.data.permission && res.data.data.permission.length > 0) {
            //   for (var i = 0; i < res.data.data.permission.length; i++) {
            //     let checkedData = [];
            //     // vue 的深入响应式原理导致的，官方说法和解决方法：
            //     // Vue 不允许在已经创建的实例上动态添加新的根级响应式属性 (root-level reactive property)
            //     // 然而它可以使用 Vue.set(object, key, value) 方法将响应属性添加到嵌套的对象上
            //     this.$set(res.data.data.permission[i], "isCheck", false);
            //     this.$set(res.data.data.permission[i], "isIndeterminate", true);

            //     // res.data.data.permission[i].isCheck = false
            //     // res.data.data.permission[i].isIndeterminate = true
            //     let have = 0;
            //     for (
            //       var j = 0;
            //       j < res.data.data.permission[i].perlist.length;
            //       j++
            //     ) {
            //       if (res.data.data.permission[i].perlist[j].is_have) {
            //         have++;
            //         checkedData.push(res.data.data.permission[i].perlist[j].id);
            //       }
            //     }
            //     if (
            //       parseInt(have) == res.data.data.permission[i].perlist.length
            //     ) {
            //       this.$set(res.data.data.permission[i], "isCheck", true);
            //       this.$set(
            //         res.data.data.permission[i],
            //         "isIndeterminate",
            //         false
            //       );
            //       this.isallCheck = true;
            //       this.isIndeterminate = false;
            //     } else {
            //       this.isallCheck = false;
            //       this.$set(
            //         res.data.data.permission[i],
            //         "isIndeterminate",
            //         false
            //       );
            //     }
            //     this.$set(
            //       res.data.data.permission[i],
            //       "checkedData",
            //       checkedData
            //     );
            //     // res.data.data.permission[i].checkedData = checkedData
            //   }
            //   this.permissionData = res.data.data.permission;
            // }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 权限数据
    requestPermission(club_admin, department_admin, club_id, departmentId) {
      let params = {};
      if (department_admin) {
        params = {
          action: "department",
          user_id: this.userid,
          department_id: departmentId,
        };
      }
      if (club_admin) {
        params = {
          action: "club",
          user_id: this.userid,
          club_id: club_id,
        };
      }

      this.$axios({
        url: "/backend/permission_query/",
        methods: "get",
        params: params,
      })
        .then((res) => {
          if (res.data.success) {
            if (res.data.data && res.data.data.length > 0) {
              for (var i = 0; i < res.data.data.length; i++) {
                let checkedData = [];
                this.$set(res.data.data[i], "isCheck", false);
                this.$set(res.data.data[i], "isIndeterminate", true);
                let have = 0;
                for (var j = 0; j < res.data.data[i].perlist.length; j++) {
                  if (res.data.data[i].perlist[j].is_have) {
                    have++;
                    checkedData.push(res.data.data[i].perlist[j].id);
                  }
                }
                if (parseInt(have) == res.data.data[i].perlist.length) {
                  this.$set(res.data.data[i], "isCheck", true);
                  this.$set(res.data.data[i], "isIndeterminate", false);
                  this.isallCheck = true;
                  this.isIndeterminate = false;
                } else {
                  this.isallCheck = false;
                  this.$set(res.data.data[i], "isIndeterminate", false);
                }
                this.$set(res.data.data[i], "checkedData", checkedData);
              }
            }
            this.permissionData = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 部门选择
    partChange(value) {
      // 如果是社团管理员
      if (this.club_admin) {
        return false;
      }

      if (parseInt(value) == 0) {
        this.permissionData = [];
        return false;
      }
      this.$axios({
        url: "/backend/search_department/",
        method: "get",
        params: {
          department_id: value,
        },
      })
        .then((res) => {
          if (res.data.success) {
            if (res.data.data && res.data.data.length > 0) {
              for (var i = 0; i < res.data.data.length; i++) {
                let checkedData = [];
                this.$set(res.data.data[i], "isCheck", false);
                this.$set(res.data.data[i], "isIndeterminate", true);
                let have = 0;
                for (var j = 0; j < res.data.data[i].perlist.length; j++) {
                  if (res.data.data[i].perlist[j].is_have) {
                    have++;
                    checkedData.push(res.data.data[i].perlist[j].id);
                  }
                }
                if (parseInt(have) == res.data.data[i].perlist.length) {
                  this.$set(res.data.data[i], "isCheck", true);
                  this.$set(res.data.data[i], "isIndeterminate", false);
                  this.isallCheck = true;
                  this.isIndeterminate = false;
                } else {
                  this.isallCheck = false;
                  this.$set(res.data.data[i], "isIndeterminate", false);
                }
                this.$set(res.data.data[i], "checkedData", checkedData);
              }
            }
            this.permissionData = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // all 全选
    allCheck(val) {
      let permissionData = this.permissionData;
      if (val) {
        for (var i = 0; i < permissionData.length; i++) {
          // permissionData[i].isCheck = true
          this.$set(permissionData[i], "isCheck", true);
          let arr = [];
          for (let j = 0; j < permissionData[i].perlist.length; j++) {
            arr[j] = permissionData[i].perlist[j].id;
          }
          this.$set(permissionData[i], "checkedData", arr);
          this.$set(permissionData[i], "isIndeterminate", false);
        }
      } else {
        for (var i = 0; i < permissionData.length; i++) {
          // permissionData[i].isCheck = true
          this.$set(permissionData[i], "isCheck", false);
          let arr = [];
          this.$set(permissionData[i], "checkedData", arr);
          this.$set(permissionData[i], "isIndeterminate", false);
        }
      }
      this.permissionData = permissionData;
    },

    handleCheckAllChange(val, index) {
      // 全选 val 是否全选
      let arr = [];
      const re = this.permissionData[index].perlist;
      //全选
      if (val) {
        for (let i = 0; i < re.length; i++) {
          arr[i] = re[i]["id"];
        }
      }
      // this.permissionData[index].checkedData = arr;
      // this.permissionData[index].isIndeterminate = false;
      this.$set(this.permissionData[index], "checkedData", arr);
      this.$set(this.permissionData[index], "isIndeterminate", false);

      let permissionData = this.permissionData;
      let have = 0;
      for (var i = 0; i < permissionData.length; i++) {
        if (permissionData[i].isCheck) {
          have++;
        }
      }
      if (parseInt(have) == permissionData.length) {
        this.isallCheck = true;
        this.isIndeterminate = false;
      } else {
        this.isallCheck = false;
        this.isIndeterminate = true;
      }
    },

    handleCheckedCitiesChange(val, index) {
      let checkedCount = val.length;
      // this.permissionData[index].isCheck =
      //   checkedCount === this.permissionData[index].perlist.length;

      // this.permissionData[index].isIndeterminate =
      //   checkedCount > 0 && checkedCount < this.permissionData[index].perlist.length;

      this.$set(
        this.permissionData[index],
        "isCheck",
        checkedCount === this.permissionData[index].perlist.length
      );
      this.$set(
        this.permissionData[index],
        "isIndeterminate",
        checkedCount > 0 &&
          checkedCount < this.permissionData[index].perlist.length
      );
      let permissionData = this.permissionData;
      let have = 0;
      for (var i = 0; i < permissionData.length; i++) {
        if (permissionData[i].isCheck) {
          have++;
        }
      }
      if (parseInt(have) == permissionData.length) {
        this.isallCheck = true;
        this.isIndeterminate = false;
      } else {
        this.isallCheck = false;
        this.isIndeterminate = true;
      }
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    // 失焦添加标签
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        let formdata = new FormData();
        formdata.set("tagName", inputValue);
        formdata.set("club_id", this.userid);
        formdata.set("user_id", this.clubid);
        this.$axios({
          url: "/backend/create_label/",
          method: "post",
          data: formdata,
        })
          .then((res) => {
            if (res.data.success) {
              this.$message.success("添加标签成功！");
            } else {
              this.$message.error(res.data.error);
            }
          })
          .catch((error) => {
            this.$message.error(error);
          });
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    // 删除标签
    handleClose(tag) {
      let formdata = new FormData();
      formdata.set("tagName", tag);
      formdata.set("club_id", this.userid);
      formdata.set("user_id", this.clubid);
      this.$axios({
        url: "/backend/delete_label/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("删除标签成功！");
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    // 保存
    UpdateUser() {
      if (
        (this.gradename === "" && this.clas === "") ||
        (this.gradename != "" && this.clas != "")
      ) {
        let permissionData = this.permissionData;
        let perIds = [];
        for (let i = 0; i < permissionData.length; i++) {
          if (permissionData[i].isCheck) {
            perIds.push(permissionData[i].id);
            for (let j = 0; j < permissionData[i].checkedData.length; j++) {
              perIds.push(permissionData[i].checkedData[j]);
            }
          } else {
            for (let j = 0; j < permissionData[i].checkedData.length; j++) {
              perIds.push(permissionData[i].checkedData[j]);
            }
            if (permissionData[i].checkedData.length > 0) {
              perIds.push(permissionData[i].id);
            }
          }
        }
        let department_id = this.value ? this.value : "";
        let position_id = this.jobvalue ? this.jobvalue : "";
        if (parseInt(department_id) == 0) {
          department_id = "";
        } else {
          department_id = this.value ? this.value : "";
        }
        let formdata = new FormData();
        formdata.set("user_id", this.userid);
        formdata.set("admin_id", localStorage.getItem("admin_id"));
        formdata.set("department_id", department_id);
        formdata.set("position_id", position_id);
        formdata.set("club_id", this.clubid);
        formdata.set("perIds", JSON.stringify(perIds));
        formdata.set("grade_name", this.gradename);
        formdata.set("class_name", this.clas);
        formdata.set("name", this.data.username);
        this.$axios({
          url: "/backend/change_club_user/",
          method: "post",
          data: formdata,
        })
          .then((res) => {
            if (res.data.success) {
              this.$message.success("保存成功");
              if (this.is_self) {
                this.$router.push({
                  path: "/index/",
                });
              } else {
                this.$router.push({
                  path: "/club/user",
                  query: {
                    id: this.clubid,
                  },
                });
              }
            } else {
              this.$message.error(res.data.error);
            }
          })
          .catch((error) => {
            this.$message.error(error);
          });
      } else {
        if (this.gradename === "") {
          this.$message.error("请选择入学年份");
        }
        if (this.clas === "") {
          this.$message.error("请选择专业");
        }
      }
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.data-info {
  margin-top: 30px;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-card {
  // height: 70vh;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.el-col-6 {
  margin-bottom: 20px;
}

.bg-purple {
  background: #fff;
  border: 1px solid #c9c9c9;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

// .el-input /deep/ .el-input__inner {
//   border: none;
// }

.el-input.is-disabled /deep/ .el-input__inner {
  background: #fff;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.choose_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
