<template>
  <div id="login-container">
    <div class="loginbox">
      <div class="logincenter">
        <div v-if="codeLogin">
          <div class="login-top">
            <div class="logtext">扫码登录</div>
            <!-- <img class="code-btn" @click="codeClick" src="@/assets/image/qrcode.png" alt=""> -->
            <el-button @click="codeClick" class="code-btn" type="info"
              >密码登录</el-button
            >
          </div>
          <div id="codebox">
            <wxlogin
              :appid="'wx3e3e8c8278d8bc74'"
              :scope="'snsapi_login'"
              :theme="'white'"
              :redirect_uri="encodeURIComponent(href)"
              :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAuc3RhdHVzLnN0YXR1c19icm93c2VyIHsKZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAucXJjb2RlIHsKYm9yZGVyOiBub25lOwp3aWR0aDogMjAwcHg7CmhlaWdodDogMjAwcHg7Cn0KLmltcG93ZXJCb3ggLnN0YXR1c3sKZGlzcGxheTogbm9uZQp9Ci5pbXBvd2VyQm94IC53cnBfY29kZXsKICAgIG1hcmdpbi10b3A6IDEwMHB4Owp9'"
              rel="external nofollow"
            ></wxlogin>
          </div>
          <div style="margin-top: 30px; font-weight: bold;text-align:center">
            打开微信扫码登陆
          </div>
        </div>
        <div
          v-else
          style="display: flex; flex-direction: column; align-items: center"
        >
          <div class="login-top">
            <div class="logtext" style="margin-bottom: 40px">账号密码登录</div>
            <img
              class="qrcode"
              @click="codeClick"
              src="@/assets/image/qrcode.png"
              alt=""
            />
          </div>
          <div class="username" style="margin-bottom: 20px">
            <input type="text" v-model="name" placeholder="输入账号" />
          </div>
          <div class="password">
            <input type="password" v-model="password" placeholder="输入密码" />
          </div>
          <el-button class="login-btn" @click="login" type="primary"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import wxlogin from "vue-wxlogin";
export default {
  naem: "Login",
  components: {
    wxlogin,
  },
  props: {},
  data() {
    return {
      codeLogin: false,
      name: "",
      password: "",
      href: 'https://lhsv.gazrey.com/',
      // href: window.location.href,
      // href: 'https://lihuashuadmin.gazrey.com',
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    getCode() {
      this.code = this.getUrlCode().code; // 截取code
      if (this.code == null || this.code === "") {
      } else {
        this.$axios({
          url: '/',
          method: 'get',
          params: {
            code: this.code
          }
        }).then((res)=> {
          if (res.data.success) {
            this.$store.commit("setUserid", res.data.user_id);
            this.$store.commit("setUsername", res.data.user_name);
            this.$store.commit("setUserimg", res.data.header);
            localStorage.setItem('is_superadmin', res.data.is_superadmin)
            if (res.data.is_superadmin) {
              localStorage.setItem('perlist', res.data.perlist)
            } 
            this.$message({
              message: "登录成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.push("/index/");
            }, 1000);
          } else {
            this.$message.error(res.error);
          }
        }).catch((error) => {
          this.$message.error(error);
        })
      }
    },

    getUrlCode() {
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },

    codeClick() {
      this.codeLogin = !this.codeLogin;
    },
    login() {
      let param = new URLSearchParams();
      param.append("name", this.name);
      param.append("password", this.password);
      this.$axios({
        method: "post",
        url: "/backend/login_api/",
        data: param,
        withCredentials:true
      })
        .then((res) => {
          if (res.data.success) {
            // 保存本地缓存userid
            this.$store.commit("setUserid", res.data.user_id);
            this.$store.commit("setUsername", res.data.user_name);
            this.$store.commit("setUserimg", res.data.header);
            localStorage.setItem('is_superadmin', res.data.is_superadmin)
            if (res.data.is_superadmin) {
              localStorage.setItem('perlist', res.data.perlist)
            } 
            this.$message({
              message: "登录成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.push("/index/");
            }, 1000);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>


<style lang="less" scoped>
#login-container {
  font-size: 1vw;
  background: url("/login/bg.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
.loginbox {
  width: 60vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .logincenter {
    width: 27vw;
    background: #fff;
    border-radius: 10px;
    padding: 2vw;
    box-sizing: border-box;
    box-shadow: 0 0 2px #c9c9c9;
    .login-top {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    #codebox {
      margin-top: 10px;
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      .codeimg {
        width: 200px;
        height: 200px;
        background: #c9c9c9;
      }
    }
    .code-btn {
      position: relative;
      top: -1.3vw;
      left: 1.3vw;
    }
    .qrcode {
      width: 3.5vw;
      height: 3.5vw;
      position: relative;
      top: -1.3vw;
      left: 1.3vw;
    }
    .logtext {
      font-size: 20px;
      color: #585ebc;
      font-weight: bold;
    }
    .username,
    .password {
      width: 100%;
      height: 100%;
      padding: 0 0.8vw;
      box-sizing: border-box;
      margin-top: 10px;
    }
    input {
      width: 100%;
      height: 0.8vw;
      padding: 1.2vw;
      box-sizing: border-box;
      border-radius: 10px;
      font-size: 18px;
    }
    .login-btn {
      margin-top: 30px;
      width: 70%;
    }
  }
}
</style>