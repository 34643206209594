<template>
  <div class="box">
    <div class="index-container">
      <div class="lihua-list">
        <!-- 总管理员 -->
        <template v-if="isadmin">
          <div
            class="lihua-item"
            v-for="(item, index) in lihualist"
            :key="index"
            :data-link="item.link"
            @click="lihuInfoAdmin"
          >
            <div class="lihuaimg">
              <img :src="item.image" alt="" />
            </div>
            <div class="lihuaname">{{ item.name }}</div>
          </div>
        </template>
        <!-- 社团 部门 班级 管理员 -->
        <template v-else>
          <div
            class="lihua-item"
            v-for="(item, index) in data"
            :key="index"
            :data-id="item.id"
            :data-type="item.type"
            :data-admin_id="item.admin_id"
            :data-perlist="item.perlist"
            :data-clubid="item.clubid"
            @click="lihuInfo"
          >
            <!-- 社团管理员 -->
            <div v-if="item.type == 1">
              <div
                class="lihuaimg"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  :src="imgurl + item.logo"
                  alt=""
                  style="width: 77px; height: 77px"
                />
              </div>
              <div class="lihuaname">{{ item.club_shortname }}</div>
            </div>
            <!-- 部门管理员 -->
            <div v-if="item.type == 2">
              <div
                class="lihuaimg"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  :src="imgurl + item.logo"
                  alt=""
                  style="width: 77px; height: 77px"
                />
              </div>
              <div class="lihuaname">{{ item.department_name }}</div>
            </div>
            <!-- 班级管理员 -->
            <div v-if="item.type == 3">
              <div class="lihuaimg">
                <img alt="" style="background: #c9c9c9" />
              </div>
              <div class="lihuaname">{{ item.className }}</div>
            </div>
          </div>
          <!-- 个人业务介绍 -->
          <div class="lihua-item" @click="businessInfo">
            <div class="lihuaimg">
              <img
                src="/index/business.png"
                alt=""
                style="background: #c9c9c9"
              />
            </div>
            <div class="lihuaname">业务介绍</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  components: {},
  props: {},
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      lihualist: [
        {
          link: "/information/",
          name: "基础信息管理",
          image: "/index/Basic_info.png",
        },
        {
          link: "/home/",
          name: "首页管理",
          image: "/index/index.png",
        },
        {
          link: "/liyouneed/",
          name: "梨友需求管理",
          image: "/index/liyou_demand.png",
        },
        {
          link: "/adminClub/",
          name: "社团管理",
          image: "/index/community.png",
        },
        // {
        //   link: '/brandActivity/',
        //   name: "品牌活动",
        //   image: "/index/brand.png",
        // },
        {
          link: "/usermanger/",
          name: "用户管理",
          image: "/index/user.png",
        },
        // {
        //   name: "舆情监控",
        //   image: "/index/monitoring.png",
        // },
        // {
        //   name: "班级管理",
        //   image: "/index/shetuan.png",
        // },
        {
          link: "/business/",
          name: "业务介绍",
          image: "/index/business.png",
        },
      ],
      //type 0 平台管理员 1 社团管理员 2 部门管理员 3 班级管理员
      data: [],
      isadmin: false, // 是否是总管理员
      url: "",
    };
  },
  created() {
    this.requestData();
  },
  methods: {
    // 平台管理员
    lihuInfoAdmin(e) {
      if (
        e.currentTarget.dataset.link == "/information/" ||
        e.currentTarget.dataset.link == "/adminClub/"
      ) {
        this.$router.push(e.currentTarget.dataset.link);
      }
      if (e.currentTarget.dataset.link == "/home/") {
        this.$router.push("/adminhome/");
      }
      if (e.currentTarget.dataset.link == "/liyouneed/") {
        this.$router.push("/needs/");
      }
      // if (e.currentTarget.dataset.link == '/brandActivity/') {
      //   window.location.href = 'https://lhs.gazrey.com/backend/brand/week/'
      // }
      if (e.currentTarget.dataset.link == "/usermanger/") {
        this.$router.push("/adminuser/");
      }
      if (e.currentTarget.dataset.link == "/business/") {
        this.$router.push("/business/");
      }
    },

    // 社团 部门 班级 管理员
    lihuInfo(e) {
      let id = e.currentTarget.dataset.id;
      let type = e.currentTarget.dataset.type;
      let admin_id = e.currentTarget.dataset.admin_id
      let perlist = e.currentTarget.dataset.perlist
      let clubid = e.currentTarget.dataset.clubid
      localStorage.setItem("admin_id", admin_id);
      localStorage.setItem("perlist", perlist);
      localStorage.setItem("usertype", type);
      // 社团
      if (type == 1) {
        this.$router.push("/club?id=" + id);
        // 本地存储clubid （消息使用）
        localStorage.setItem("clubid", id);
      } else if (type == 2) {
        // 部门
        // this.$router.push("/partment?id=" + id);
        this.$router.push("/club?id=" + clubid);
        localStorage.setItem("clubid", clubid);
        localStorage.setItem("department_id", id);
      } else if (type == 3) {
        // 班级
        this.$router.push("/class?id=" + id);
      }
    },

    // 业务介绍
    businessInfo() {
      this.$router.push("/business/");
    },

    // 数据请求
    requestData() {
      this.$axios({
        method: "get",
        url: "/backend/lihuashu_index/",
        withCredentials:true
      })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
            this.isadmin = res.data.is_superadmin;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  font-size: 1vw;
  background: url("/login/bg.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  .index-container {
    width: 40vw;
    margin: 0 auto;
    height: 100%;
    padding: 26vh 0;
    box-sizing: border-box;
    .lihua-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .lihua-list::after {
      content: "";
      width: 12vw;
    }
    .lihua-item {
      width: 12vw;
      height: 8vw;
      background: #fff;
      border-radius: 8px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 2px #c9c9c9;
    }
    .lihua-item:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    .lihuaimg {
      width: 158px;
      height: 77px;
      img {
        width: 158px;
        height: 77px;
      }
    }
    .lihuaname {
      margin-top: 10px;
      font-weight: 500;
      color: #3065dc;
      text-align: center;
    }
  }
}
</style>
