<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '' }"
            >梨友需求管理</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="search_box" v-if="isshowlistbtn">
        <el-input
          placeholder="请输入标题"
          @clear="clearPageGetData"
          v-model="pageData.search"
          clearable
        >
        </el-input>
        <el-button
          style="margin-left: 20px"
          type="primary"
          @click="clearPageGetData"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <!-- <div class="info-operate">
        <el-button type="primary" @click="addActivity">新增</el-button>
      </div> -->
      <div class="info-tab" v-if="isshowlistbtn">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="80"></el-table-column>
              <el-table-column label="ID" prop="id" width="80">
              </el-table-column>
              <el-table-column label="标题" prop="title"></el-table-column>
              <el-table-column
                label="发布类型"
                prop="classify_name"
              ></el-table-column>
              <el-table-column
                label="所属行业"
                prop="industry_name"
              ></el-table-column>
              <el-table-column
                label="发布时间"
                prop="releaseDate"
              ></el-table-column>
              <el-table-column
                label="发布人"
                prop="baseuser_name"
              ></el-table-column>
              <el-table-column label="浏览量" prop="seenum"></el-table-column>
              <el-table-column label="状态" width="100">
                <template slot-scope="scope">
                  <div>{{ scope.row.is_online ? "已上线" : "已下线" }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作"  width="140">
                <template slot-scope="scope">
                  <div class="btns">
                    <el-button
                      v-if="isshoweditbtn"
                      size="mini"
                      type="primary"
                      @click="needsInfo(scope.row.id)"
                      >查看</el-button
                    >
                    <!-- <el-button
                      v-if="!isshoweditbtn"
                      size="mini"
                      type="primary"
                      @click="needsInfo(scope.row.id)"
                      >查看</el-button
                    > -->
                    <el-button
                      v-if="isshoweditbtn"
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >{{ scope.row.is_online ? "下线" : "上线" }}</el-button
                    >
                  </div>

                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.pageNum"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  name: "Needs",
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
        search: "",
      },
      enroolData: {
        id: "",
        currentPage: 1,
        pageNum: 10,
        search: "",
      },
      isshowlistbtn: true,
      isshoweditbtn: true
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('梨友需求查看')) == -1) {
      this.isshowlistbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('梨友需求编辑')) == -1) {
      this.isshoweditbtn = false
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    // 清除page重载列表
    clearPageGetData(){
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios({
        url: "/backend/needs_list/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 需求详情
    needsInfo(id) {
      this.$router.push({
        path: "/needsInfo/",
        query: {
          id: id,
        },
      });
    },

    // 下线
    handleDelete(e, row) {
      let id = row.id;
      let needs_ids = [];
      needs_ids.push(id);
      let isonline = row.is_online;
      let status = 0;
      if (isonline) {
        status = 1;
      } else {
        status = 0;
      }
      let formdata = new FormData();
      formdata.set("ids", JSON.stringify(needs_ids));
      formdata.set("action", status);
      this.$axios({
        url: "/backend/needs_oper/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("操作成功");
            this.getData();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-operate {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
.info-breadcrumb{
  margin-top: 10px;
}
.btns{
  display: flex;
}

// 搜索框
.search_box {
  margin-top: 40px;
  width: 25%;
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
