<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >首页管理</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="info-tab">
        <template>
          <el-tabs
            class="tab-self"
            v-model="activeName"
            type="card"
            @tab-click="handleClick"
            :before-leave="beforeLeave"
          >
            <el-tab-pane label="Banner管理" name="banner">
              <Banner ref="banner"></Banner>
            </el-tab-pane>
            <el-tab-pane label="官方活动" name="officialac">
              <OfficialAc ref="officialac"></OfficialAc>
            </el-tab-pane>
            <el-tab-pane label="精选推荐" name="selectrecd">
              <SelectRecd ref="selectrecd"></SelectRecd>
            </el-tab-pane>

            <el-tab-pane name="CustoBtn">
              <span slot="label">
                <el-button type="primary" @click="cpnsNew">新增</el-button>
                <!-- <el-link type="primary" :underline="false">刷新</el-link> -->
              </span>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner';
import OfficialAc from '@/components/OfficialAc'
import SelectRecd from '@/components/SelectRecd'

export default {
  name: "AdminHome",
  props: {},
  components: {
    Banner,
    OfficialAc,
    SelectRecd,
  },
  data() {
    return {
      activeName: "banner",
    };
  },
  created() {},
  mounted() {
    this.tableLocal();
  },
  methods: {
    beforeLeave(visitName,currentName){
      if (visitName=='CustoBtn') {
        return false;
      }
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      if (tab.name == 'banner') {
        sessionStorage.setItem('adminhome', tab.name)
        if(String(localStorage.getItem('perlist').indexOf('Banner管理查看')) == -1) {
          this.$message.error('无权限查看')
          return false
        }
      }
      if (tab.name == 'officialac') {
        sessionStorage.setItem('adminhome', tab.name)
        if(String(localStorage.getItem('perlist').indexOf('官方活动查看')) == -1) {
          this.$message.error('无权限查看')
          return false
        }
      }
      if (tab.name == 'selectrecd') {
        sessionStorage.setItem('adminhome', tab.name)
        if(String(localStorage.getItem('perlist').indexOf('精选推荐查看')) == -1) {
          this.$message.error('无权限查看')
          return false
        }
      }
      
      if (tab.name != 'CustoBtn') {
        let that = this;
        setTimeout(() => {
          that.onData();
        }, 50);
      }
      
    },
    cpnsNew(){
        if (sessionStorage.getItem("adminhome") == 'banner') {
          this.$refs.banner.addBanner();
        } else if (sessionStorage.getItem("adminhome") == 'officialac') {
          this.$refs.officialac.addOfficialAc();
        } else if (sessionStorage.getItem("adminhome") == 'selectrecd') {
          this.$refs.selectrecd.addSelectRecd();
        }
    },
    onData() {
      this.$refs[this.activeName].getData();
    },
    // 页面刷新
    tableLocal() {
      if (sessionStorage.getItem("adminhome") != null) {
        this.activeName = sessionStorage.getItem("adminhome");
        this.onData();
      } else {
        this.onData()
      }
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;
    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 20px;
    }
  }

  // 改动新增按钮  ——————————————
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none !important;
    width: 100%;
    display: flex;
  }
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
    border: none !important;
  }
  /deep/ .tab-self .el-tabs__item:last-child {
    width: calc(100% - 240px);
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background: none !important;
    border: none !important;
    padding: 0px !important;
    display: flex;
    justify-content: flex-end;
    cursor: default;
  }
  /deep/ .tab-self .el-tabs__item:first-child {
    border: 1px solid #E4E7ED !important;
    border-radius: 4px 0 0 0;
  }
  /deep/ .tab-self .el-tabs__item:nth-child(2) {
    border: 1px solid #E4E7ED !important;
    border-left: none !important;
  }
  /deep/ .tab-self .el-tabs__item:nth-child(3) {
    border: 1px solid #E4E7ED !important;
    border-left: none !important;
    border-radius: 0 4px 0 0;
  }
  // 改动end ——————————————
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
.info-breadcrumb{
  margin-top: 10px;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>