<template>
  <div>
    <el-table :data="data">
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column
        label="签到时间"
        width="170"
        prop="signDate"
      ></el-table-column>
      <el-table-column label="联系方式" prop="phonenum"></el-table-column>
      <el-table-column label="报名类型">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.type == 0
                ? "参与者"
                : scope.row.type == 1
                ? scope.row.voluntname
                : ""
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.state == 2
                ? "已签到"
                : scope.row.state == 1
                ? "未签到"
                : scope.row.state == 0
                ? "已取消"
                : scope.row.state == 3
                ? "已过期"
                : scope.row.state == 4
                ? "活动取消"
                : scope.row.state == 5
                ? "签到失败"
                : scope.row.state == 6
                ? "被分配"
                : ""
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="公益时长（小时）"
        width="160"
        prop="time"
      ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <!-- 志愿者 -->
          <template v-if="scope.row.type == 1 && scope.row.state == 1">
            <el-button
              v-if="!isoverdue"
              size="mini"
              type="success"
              @click="handleSign(scope.$index, scope.row)"
              >签到</el-button
            >
            <el-button
              v-else
              size="mini"
              type="success"
              @click="handleDistribution(scope.$index, scope.row)"
              >分配时长</el-button
            >
          </template>
          <template v-else>
            <el-button
              v-if="scope.row.state == 1"
              size="mini"
              type="success"
              @click="handleSign(scope.$index, scope.row)"
              >签到</el-button
            >
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px"
      background
      layout="prev, pager, next"
      :page-size="enroolData.pageNum"
      @current-change="handleCurrentChange"
      :total="totalNum"
      :current-page="enroolData.currentPage"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "EnrollList",
  props: ["data", "totalNum", "enroolData", "isoverdue"],
  data() {
    return {
      dialogTableVisible: false,
    };
  },
  mounted() {},
  methods: {
    handleCurrentChange(currentpage) {
      this.enroolData.currentPage = currentpage;
      this.$emit("endata", this.enroolData);
    },
    // 签到
    handleSign(index, row) {
      let data = {
        index: index,
        row: row,
      };
      this.$emit("sign", data);
    },
    // 分配时长
    handleDistribution(index, row) {
      let data = {
        index: index,
        row: row,
      };
      this.$emit('distribution', data)
    }
  },
};
</script>

<style lang="less" scoped>
</style>
