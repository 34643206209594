<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text">部门</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="search_box">
        <el-input
          placeholder="请输入部门名称"
          @clear="clearPageGetData"
          v-model="pageData.search"
          clearable
        >
        </el-input>
        <el-button
          style="margin-left: 20px"
          type="primary"
          @click="clearPageGetData"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div class="info-operate">
        <el-button type="primary" v-if="isshowaddbtn" @click="addPart">新增</el-button>
        <!-- <el-button type="danger">删除</el-button> -->
      </div>
      <div class="info-tab">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="100"></el-table-column>
              <el-table-column label="ID" width="100" prop="id">
              </el-table-column>
              <el-table-column label="名称" prop="name"></el-table-column>
              <el-table-column label="人数" prop="num"></el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    v-if="isshoweditbtn"
                    @click="handleInfo(scope.row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="primary"
                    v-if="!isshoweditbtn"
                    @click="handleInfo(scope.row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    v-if="isshowdelbtn"
                    @click="handleDelete(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.pageNum"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Part",
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
        search: "",
      },
      isshoweditbtn: true,
      isshowaddbtn: true,
      isshowdelbtn: true
    };
  },
  created() {
    this.clubid = this.$route.query.id;
    this.pageData.id = this.$route.query.id;
    this.getData();
  },
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('部门编辑')) == -1) {
      this.isshoweditbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('部门新增')) == -1) {
      this.isshowaddbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('部门删除')) == -1) {
      this.isshowdelbtn = false
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    // 部门详情
    handleInfo(id) {
      this.$router.push({
        path: "/club/partInfo",
        query: {
          id: id,
          clubid: this.clubid,
        },
      });
    },

    // 清除page重载列表
    clearPageGetData(){
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios({
        url: "/web/getClubAllPart/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 新增部门
    addPart() {
      this.$router.push({
        path: "/club/addPart",
        query: {
          clubid: this.clubid,
        },
      });
    },

    // 删除
    handleDelete(row) {
      let id = row.id;
      let department_ids = [];
      department_ids.push(id);
      let formdata = new FormData();
      formdata.append("club_id", this.clubid);
      formdata.append("department_ids", JSON.stringify(department_ids));
      this.$confirm("是否删除此部门?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/delete_department/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
    .info-operate {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

// 搜索框
.search_box {
  margin-top: 40px;
  width: 25%;
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
