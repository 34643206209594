<template>
  <div class="info-table">
      <div class="flexbox">
        <div class="search_box">
          <el-input
            placeholder="请输入姓名"
            @clear="clearPageGetData"
            v-model="pageData.search"
            clearable
          >
          </el-input>
          <el-button
            style="margin-left: 20px"
            type="primary"
            @click="clearPageGetData"
            icon="el-icon-search"
            >搜索</el-button
          >
        </div>
        <div class="info-operate" style="margin-bottom: 20px">
          <el-button type="primary" v-if="isshowaddbtn" @click="addManage">新增</el-button>
        </div>
      </div>

    <template>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column label="ID" prop="id" width="100">
        </el-table-column>
        <el-table-column label="账号" prop="user_account_name"></el-table-column>
        <el-table-column label="姓名" prop="user_name"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="isshoweditbtn"
              size="mini"
              type="primary"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="!isshoweditbtn"
              size="mini"
              type="primary"
              @click="handleEdit(scope.row)"
              >查看</el-button
            >
            <el-button
              v-if="isshowdelbtn"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="info-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageData.limit"
        @current-change="handleCurrentChange"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "Manage",
  data() {
    return {
      tableData: [],
      totalNum: 0,
      pageData: {
        page: 1,
        limit: 10,
      },
      isshoweditbtn: true,
      isshowaddbtn: true,
      isshowdelbtn: true
    };
  },
  created() {},
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('平台管理员编辑')) == -1) {
      this.isshoweditbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('平台管理员新增')) == -1) {
      this.isshowaddbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('平台管理员删除')) == -1) {
      this.isshowdelbtn = false
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.page = currentpage;
      this.getData();
    },

    // 清除page重载列表
    clearPageGetData(){
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios
        .get("/backend/admin_list/", { params: this.pageData })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 编辑平台管理员
    handleEdit(e) {
      let id = e.id
      this.$router.push({
        path: '/adminUserInfo/',
        query: {
          id: id,
          userid: e.user_id
        }
      })
    },

    // 首页banner详情
    bannerInfo(id) {
      this.$router.push({
        path: '/bannerinfo',
        query: {
          'id': id
        }
      })
    },

    // 删除banner
    handleDelete(e) {
      let id = e.id;
      let ids = [];
      ids.push(id);
      let formdata = new FormData();
      formdata.append("ids", JSON.stringify(ids));
      this.$confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/admin_delete/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 新增banner
    addManage() {
      this.$router.push("/addManage/");
    },
  },
};
</script>

<style lang="less" scoped>
// 搜索框
.search_box {
  width: 25%;
  display: flex;
  align-items: center;
}
.information .info-container .info-table {
  margin-top: 5px !important;
}

.flexbox{
  display: flex;
  justify-content: space-between;
  // margin-top: 40px;
  align-items: flex-start;
}
</style>
