<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >基础信息管理</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="info-tab">
        <template>
          <el-tabs
            class="tab-self"
            v-model="activeName"
            type="card"
            @tab-click="handleClick"
            :before-leave="beforeLeave"
          >
            <el-tab-pane label="行业分类" name="industry">
              <Industry ref="industry"></Industry>
            </el-tab-pane>
            <el-tab-pane label="梨友分类" name="lyclassify">
              <LiyouMan ref="lyclassify"></LiyouMan>
            </el-tab-pane>

            <el-tab-pane name="CustoBtn">
              <span slot="label" @click="cpnsNew">
                <el-button type="primary">新增</el-button>
                <!-- <el-link type="primary" :underline="false">刷新</el-link> -->
              </span>
            </el-tab-pane>
            <!-- <el-tab-pane label="班级" name="class">
              <ClassMan ref="class"></ClassMan>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="学生信息" name="studentinfo">
                        <StudentMan ref="studentinfo"></StudentMan>
                    </el-tab-pane>
                    <el-tab-pane label="系统消息" name="message">
                        <SystemMessage ref="message"></SystemMessage>
                    </el-tab-pane> -->
          </el-tabs>

          <el-button size='mini' style='position: absolute;right:10px;top:5px;'>右边按钮</el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Industry from "@/components/IndustryMan";
import LiyouMan from "@/components/LiyouMan";
// import ClassMan from "@/components/ClassMan";
// import StudentMan from '@/components/StudentMan'
// import SystemMessage from '@/components/SystemMessage'

export default {
  name: "Information",
  props: {},
  components: {
    Industry,
    LiyouMan,
    // ClassMan,
    // StudentMan,
    // SystemMessage
  },
  data() {
    return {
      activeName: "industry",
    };
  },
  created() {},
  mounted() {
    this.tableLocal();
  },
  methods: {
    // 让点击新增按钮时不触发默认的页签事件
    beforeLeave(visitName,currentName){
      if (visitName=='CustoBtn') {
        return false;
      }
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      // localStorage.setItem("task", tab.name);
      if (tab.name == 'industry') {
        sessionStorage.setItem('task', tab.name)
        if(String(localStorage.getItem('perlist').indexOf('行业分类查看')) == -1) {
          this.$message.error('无权限查看')
          return false
        }
      }
      if (tab.name == 'lyclassify') {
        sessionStorage.setItem('task', tab.name)
        if(String(localStorage.getItem('perlist').indexOf('梨友分类查看')) == -1) {
          this.$message.error('无权限查看')
          return false
        }
      }

      // 点击“增加”时调用对应页签中组件的方法
      if (tab.name == 'CustoBtn') {
        // if (sessionStorage.getItem("task") == 'industry') {
        //   this.$refs.industry.addIndustry();
        // } else if (sessionStorage.getItem("task") == 'lyclassify') {
        //   this.$refs.lyclassify.addLiyouMan();
        // }
      }
      // console.log(tab.name);


      if (tab.name != 'CustoBtn') {
          let that = this;
          setTimeout(() => {
            that.onData();
          }, 50);
      }

    },
    cpnsNew(){
        if (sessionStorage.getItem("task") == 'industry') {
          this.$refs.industry.addIndustry();
        } else if (sessionStorage.getItem("task") == 'lyclassify') {
          this.$refs.lyclassify.addLiyouMan();
        }
    },
    onData() {
      this.$refs[this.activeName].getData();
      // element中Table组件的默认方法getData()
    },
    // 页面刷新
    tableLocal() {
      // if (localStorage.getItem("task") != null) {
      //   this.activeName = localStorage.getItem("task");
      //   this.onData();
      // }
      if (sessionStorage.getItem("task") != null && sessionStorage.getItem("task") != 'CustoBtn') {
        this.activeName = sessionStorage.getItem("task");
        this.onData();
      } else{
        this.onData()
      }
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;
    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 20px;
    }
  }

  // 改动新增按钮  ——————————————
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none !important;
    width: 100%;
    display: flex;
  }
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
    border: none !important;
  }
  /deep/ .tab-self .el-tabs__item:last-child {
    width: calc(100% - 240px);
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background: none !important;
    border: none !important;
    padding: 0px !important;
    display: flex;
    justify-content: flex-end;
    cursor: default;
  }
  /deep/ .tab-self .el-tabs__item:first-child {
    border: 1px solid #E4E7ED !important;
    border-radius: 4px 0 0 0;
  }
  /deep/ .tab-self .el-tabs__item:nth-child(2) {
    border: 1px solid #E4E7ED !important;
    border-left: none !important;
    border-radius: 0 4px 0 0;
  }
  // 改动end ——————————————

}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
.info-breadcrumb{
  margin-top: 10px;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;

}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e4e7ed;
}

// 改动新增按钮

// .el-tabs--card > .el-tabs__header .el-tabs__nav {
//   border: none !important;
//   width: 100%;
//   display: flex;
// }
// .el-tabs--card > .el-tabs__header .el-tabs__item {
//   border: none !important;
// }
// .tab-self .el-tabs__item:last-child {
//   width: calc(100% - 240px);
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   font-size: 16px;
//   font-weight: bold;
//   background: none !important;
//   border: none !important;
//   padding: 0px !important;
//   display: flex;
//   justify-content: flex-end;
// }
// .tab-self .el-tabs__item:first-child {
//   border: 1px solid #E4E7ED !important;
//   border-radius: 4px 0 0 0;
// }
// .tab-self .el-tabs__item:nth-child(2) {
//   border: 1px solid #E4E7ED !important;
//   border-left: none !important;
//   border-radius: 0 4px 0 0;
// }

// 改动end

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  // border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
