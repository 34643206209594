<template>
  <div class="box" v-if="isshowweb">
    <div class="index-container">
      <!-- 社团信息 -->
      <div class="club-info" style="width: 60%">
        <el-form ref="form" :model="data" label-width="100px">
          <el-form-item label="社团logo：">
            <el-upload
              class="avatar-uploader"
              action="/web/upload"
              :show-file-list="false"
              :on-change="handleChange"
              :http-request="httpRequest"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="社团名称：">
            <el-input v-model="data.name"></el-input>
          </el-form-item>
          <el-form-item label="社团简称：">
            <el-input v-model="data.shortName"></el-input>
          </el-form-item>
          <el-form-item label="slogan：">
            <el-input v-model="data.slogan"></el-input>
          </el-form-item>
          <!-- <el-form-item label="时间额度：">
            <el-input disabled v-model="暂定"></el-input>
          </el-form-item> -->
          <el-form-item label="入驻时间：">
            <el-input disabled v-model="data.time"></el-input>
          </el-form-item>
          <el-form-item label="额度：">
            <el-input disabled v-model="data.lines"></el-input>
            <el-button
              type="primary"
              plain
              style="margin-top: 15px"
              v-if="isshowlistbtn"
              @click="requestLines"
              >申请额度</el-button
            >
            <el-button v-if="isshowlistbtn" type="primary" @click="clubLimit" plain
              >额度记录</el-button
            >
          </el-form-item>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-button v-if="isshoweditbtn" type="primary" @click="onSubmit" style="width: 10vw"
              >确认</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="lihua-list">
        <div
          class="lihua-item"
          v-for="(item, index) in lihualist"
          :key="index"
          :data-link="item.link"
          @click="lihuaChange"
          v-show="item.isshow"
        >
          <div class="lihuaimg">
            <img :src="item.image" alt="" />
          </div>
          <div class="lihuaname">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- 申请额度弹出层 -->
    <el-dialog title="申请额度" :visible.sync="linesdialog" width="30%">
      <el-form
        ref="form"
        :rules="linesrules"
        :model="linesdata"
        label-width="150px"
      >
        <el-form-item label="额度（小时）" prop="num">
          <el-input v-model.number="linesdata.num"></el-input>
        </el-form-item>
        <el-form-item label="理由" prop="reason">
          <el-input v-model="linesdata.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="linesdialog = false">取 消</el-button>
        <el-button type="primary" @click="addLines">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "Club",
  data() {
    return {
      lihualist: [
        {
          link: "/club/user",
          name: "人员",
          image: "/index/shequn_people.png",
          isshow: true,
        },
        {
          link: "/club/activity",
          name: "活动",
          image: "/index/shequn_activity.png",
          isshow: true,
        },
        {
          link: "/club/article",
          name: "文章",
          image: "/index/shequn_article.png",
          isshow: true,
        },
        {
          link: "/club/part",
          name: "部门",
          image: "/index/shequn_dep.png",
          isshow: true,
        },
        {
          link: "/club/job",
          name: "职务",
          image: "/index/job_card.png",
          isshow: true,
        },
      ],
      clubid: "",
      data: {
        name: "",
        logo: "",
        timelines: "",
        time: "",
        slogan: ''
      },
      imageUrl: "",
      severurl: "", // 服务器图片地址
      linesdialog: false,
      linesdata: {
        num: "",
        reason: "",
      }, // 额度
      linesrules: {
        num: [
          {
            required: true,
            message: "请输入额度（小时）",
            trigger: "blur",
          },
          { type: "number", message: "额度为数字值" },
        ],
      },
      isshowlistbtn: true,
      isshowreqbtn: true,
      isshowweb: true,
      isshoweditbtn: true
    };
  },
  computed: {
    ...mapState(["imgurl"]),
  },
  created() {
    this.clubid = this.$route.query.id;
    this.$axios({
      url: "/web/getClubInfo/",
      methods: "get",
      params: {
        id: this.$route.query.id,
        admin_id: localStorage.getItem('admin_id')
      },
    })
      .then((res) => {
        if (res.data.success) {
          this.data = res.data.data;
          if (res.data.data.logo) {
            this.severurl = res.data.data.logo;
            this.imageUrl = this.imgurl + res.data.data.logo;
          } else {
            this.severurl = "";
            this.imageUrl = "";
          }
        } else {
          this.$message.error(res.data.error);
        }
      })
      .catch((error) => {
        this.$message.error(error);
      });
  },
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('额度列表')) == -1) {
      this.isshowlistbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('额度申请')) == -1) {
      this.isshowreqbtn = false
    }

    if(String(localStorage.getItem('perlist').indexOf('社团查看')) == -1) {
      this.isshowweb = true
    }
    if(String(localStorage.getItem('perlist').indexOf('社团编辑')) == -1) {
      this.isshoweditbtn = false
    }


    if(String(localStorage.getItem('perlist').indexOf('人员查看')) == -1) {
      this.lihualist[0].isshow = false
    }
    if(String(localStorage.getItem('perlist').indexOf('活动查看')) == -1) {
      this.lihualist[1].isshow = false
    }
    if(String(localStorage.getItem('perlist').indexOf('文章查看')) == -1) {
      this.lihualist[2].isshow = false
    }
    if(String(localStorage.getItem('perlist').indexOf('部门查看')) == -1) {
      this.lihualist[3].isshow = false
    }
    if(String(localStorage.getItem('perlist').indexOf('职务管理查看')) == -1) {
      this.lihualist[4].isshow = false
    }
  },
  methods: {
    // 社团人员详情
    lihuaChange(e) {
      let url = e.currentTarget.dataset.link;
      this.$router.push({
        path: url,
        query: {
          id: this.clubid,
        },
      });
      // this.$router.push('/club/user?id=' + this.clubid)
    },
    // 图片更改
    handleChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 图片上传
    httpRequest(item) {
      // 自定义文件上传
      // var up = new File([item.file], item.file.name, {
      //   type: item.file.type
      // });
      const fd = new FormData();
      fd.set("file", item.file);
      this.$axios({
        url: "/web/upload/",
        method: "post",
        data: fd,
      })
        .then((res) => {
          if (res.data.success) {
            this.severurl = res.data.imgurl;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    // 表单保存
    onSubmit() {
      let param = new URLSearchParams();
      param.append("id", this.clubid);
      param.append("name", this.data.name);
      param.append("shortName", this.data.shortName);
      param.append("logo", this.severurl);
      param.append("slogan", this.data.slogan);
      this.$axios({
        method: "post",
        url: "/web/updateClub/",
        data: param,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    // 申请额度
    requestLines() {
      this.linesdialog = true;
      this.$refs.form.resetFields();
    },
    // 确定
    addLines() {
      this.$refs.form.validate((vaild) => {
        if (vaild) {
          let formdata = new FormData();
          formdata.set("club_id", this.clubid);
          formdata.set("num", this.linesdata.num);
          formdata.set("content", this.linesdata.reason);
          this.$axios({
            url: "/backend/create_apply/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.linesdialog = false;
                this.linesdata = {
                  num: "",
                  reason: "",
                };
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        } else {
          this.$message.error("请填写正确的额度申请");
          return false;
        }
      });
    },
    // 额度记录
    clubLimit() {
      this.$router.push({
        path: "/club/limit/",
        query: {
          id: this.clubid,
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.box {
  font-size: 1vw;
  background: url("/login/bg.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .index-container {
    width: 65vw;
    margin: 0 auto;
    height: 100%;
    padding: 12vh 0 8vh 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .lihua-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .lihua-list::after {
      content: "";
      width: 12vw;
    }
    .lihua-item {
      width: 12vw;
      height: 8vw;
      background: #fff;
      border-radius: 8px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 2px #c9c9c9;
    }
    .lihua-item:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    .lihuaimg {
      width: 158px;
      height: 77px;
      img {
        width: 158px;
        height: 77px;
      }
    }
    .lihuaname {
      margin-top: 10px;
      font-weight: 500;
      color: #3065dc;
    }
    .club-info {
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 30px;
    }
    .avatar-uploader {
      display: flex;
    }
    .avatar-uploader /deep/ .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader /deep/ .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
  }
}
</style>
