<template>
  <div id="box">
    <div class="header_left" @click="goHome">
      <img class="logo" src="@/assets/image/logo.png" alt="" />
    </div>
    <div class="header_right">
      <!-- 社团申请列表 -->
      <div
        style="display: flex; align-items: center;cursor:pointer;"
        v-show="isshowapply"
        @click="applyInfo"
      >
        <img style="width: 1.2vw" src="/image/apply.png" />
        <span style="margin-left: 8px">{{ apllynum }}条申请</span>
      </div>
      <div class="name_box">
        <!-- <div style="color: #fff">{{ username }}</div>
        <div class="logout" @click="logoOut">退出</div> -->
        <el-dropdown trigger="click">
          <span
            class="el-dropdown-link"
            style="
              color: #fff;
              font-size: 18px;
              display: flex;
              align-items: center;
              cursor:pointer;
            "
          >
            <el-image class="userimg" :src="imgurl + userimg" alt="">
              <div slot="error" class="image-slot">
                <!-- <i class="el-icon-picture-outline"></i> -->
                <img src="@/assets/image/default-header.jpg" alt="">

              </div>
            </el-image>
            {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div class="box-right">
                <div @click="changePswPop">
                  <el-button style="border: none">修改密码</el-button>
                </div>
                <div>
                  <el-button style="border: none" @click="logoOut"
                    >退出登录</el-button
                  >
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 修改密码弹出层 -->
    <el-dialog title="修改密码" :visible.sync="showpop" width="30%" :modal-append-to-body='false'>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="当前密码" prop="age">
          <el-input type="password" v-model="ruleForm.age"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="canceldialog">取 消</el-button>
        <el-button type="primary" @click="addBtn">确 定</el-button>
      </span> -->
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Header",
  components: {},
  computed: {
    ...mapState(["imgurl", "apllynum"]),
  },
  props: {},
  data() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('当前密码不能为空'));
        }
        setTimeout(() => {
          // 这里改成判断是否原密码输入正确
          callback();
          // if (!Number.isInteger(value)) {
          //   callback(new Error('请输入数字值'));
          // } else {
          //   if (value < 18) {
          //     callback(new Error('必须年满18岁'));
          //   } else {
          //     callback();
          //   }
          // }
        }, 500);
      };
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      username: localStorage.getItem("username"),
      userimg: localStorage.getItem("userimg"),
      clubid: localStorage.getItem("clubid"),
      is_superadmin: localStorage.getItem("is_superadmin"),
      isshowapply: true,
      // 修改密码弹窗
      showpop:false,
      ruleForm: {
        age: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur',required: true, }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur',required: true, }
        ],
        age: [
          { validator: checkAge, trigger: 'blur',required: true, }
        ]
      }
    };
  },

  // 社团申请请求
  created() {
    if (this.clubid && this.is_superadmin === 'false') {
      this.getmessagenum(this.clubid);
    }
    if (this.is_superadmin === 'false') {
      if (this.$route.path == "/index/" || this.$route.path == "/business/" || this.$route.path == "/businessinfo/" || this.$route.path == "/addbusiness/") {
        this.isshowapply = false;
      }
    } else {
      this.isshowapply = false;
    }
  },
  mounted() {

  },

  watch: {
    $route(to, from) {
      if (this.is_superadmin == 'false') {
        if (this.$route.path == "/index/" || this.$route.path == "/business/" || this.$route.path == "/businessinfo/" || this.$route.path == "/addbusiness/") {
          this.isshowapply = false;
        } else {
          this.getmessagenum(localStorage.getItem("clubid"));
          this.isshowapply = true;
        }
      } else {
        this.isshowapply = false;
      }
    },
  },

  methods: {
    goHome() {
      this.$router.push("/index/");
    },
    logoOut() {
      this.$confirm("是否退出登陆?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/logout/",
            method: "post",
          })
            .then((res) => {
              if (res.data.success) {
                localStorage.removeItem("userid");
                localStorage.removeItem("clubid");
                localStorage.removeItem("is_superadmin");
                localStorage.removeItem("admin_id");
                localStorage.removeItem("perlist");
                this.$store.commit("setUserid", "");
                this.$store.commit("setUsername", "");
                this.$store.commit("setUserimg", "");
                this.$router.push("/");
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });

    },

    // 获取多少条申请
    getmessagenum(clubid) {
      this.$axios({
        url: "/backend/club_apply_query/",
        method: "get",
        params: {
          club_id: clubid,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.$store.commit("setapllynum", res.data.count);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 申请列表
    applyInfo() {
      this.$router.push({
        path: "/applyinfo/",
        query: {
          id: localStorage.getItem("clubid"),
        },
      });
    },

    // 修改密码
    changePswPop(){
      this.showpop = true;
      this.$refs.ruleForm.resetFields();  // 每次打开重置表单数据
    },
    changePsw(){

    },
    //
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formdata = new FormData();
            formdata.append("old_password", this.ruleForm.age);
            formdata.append("new_password", this.ruleForm.pass);
            formdata.append("again_password", this.ruleForm.checkPass);
            this.$axios({
              url: "/backend/change_pwd/",
              method: "post",
              data: formdata,
            })
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('修改成功');
                  localStorage.removeItem("userid");
                  localStorage.removeItem("clubid");
                  localStorage.removeItem("is_superadmin");
                  localStorage.removeItem("admin_id");
                  localStorage.removeItem("perlist");
                  this.$store.commit("setUserid", "");
                  this.$store.commit("setUsername", "");
                  this.$store.commit("setUserimg", "");
                  this.$router.push("/");
                } else {
                  this.$message.error(res.data.error);
                }
              })
              .catch((error) => {
                this.$message.error(error);
              });

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
  },
};
</script>

<style lang="less">
#box {
  padding: 10px 30px;
  box-sizing: border-box;
  background: #4470ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;      // 原1
  .header_left {
    width: 173px;
    height: 47px;
    .logo {
      width: 173px;
      height: 47px;
    }
  }
  .header_right {
    display: flex;
    align-items: center;
    color: #fff;

    .name_box {
      margin-left: 40px;
      display: flex;
      align-items: center;
      .logout {
        margin-left: 15px;
      }
    }
    .box-right {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

<style scoped>
/*按钮悬浮*/
.userimg {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-slot {
  width: 2vw;
  height: 2vw;
}
.image-slot img {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  object-fit: cover;
}
.el-button:hover {
  background: #fff !important;
}
.el-dropdown-menu__item:not(.is-disabled):hover{
  background: #fff;
  /* color: #4470ed; */
}
.el-button:focus, .el-button:hover {
  color: #4470ed;
}
</style>
