<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >社团管理</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="flexbox">
        <div class="search_box" v-if="isshowlistbtn">
          <el-input
            placeholder="请输入社团名称"
            @clear="clearPageGetData"
            v-model="pageData.search"
            clearable
          >
          </el-input>
          <el-button
            style="margin-left: 20px"
            type="primary"
            @click="clearPageGetData"
            icon="el-icon-search"
            >搜索</el-button
          >
        </div>
        <div class="info-operate">
          <el-button type="primary" v-if="isshowaddbtn" @click="addAdminClub"
            >新增</el-button
          >
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
      </div>

      <div class="info-tab" v-if="isshowlistbtn">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="80"></el-table-column>
              <el-table-column label="名称" prop="name"></el-table-column>
              <el-table-column label="简称" prop="shortName"></el-table-column>
              <el-table-column label="logo" width="90">
                <template slot-scope="scope">
                  <el-image
                    style="height: 70px;width:100%;"
                    :src="imgurl + scope.row.logo"
                  >
                    <div slot="error" class="image-slot" style="width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                      <img src="../assets/image/defaultImg.png" alt="" style="object-fit:cover;width:50%;height:50%;opacity:0.8;">
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="额度（小时）"
                prop="lines"
              ></el-table-column>
              <el-table-column label="人员" prop="clubusernum">
                <template slot-scope="scope">
                  <el-link type="primary" @click="personInfo(scope.row.id)">{{
                    scope.row.clubusernum
                  }}</el-link>
                </template>
              </el-table-column>
              <el-table-column label="部门" prop="departmentnum">
                <template slot-scope="scope">
                  <el-link type="primary" @click="partInfo(scope.row.id)">{{
                    scope.row.departmentnum
                  }}</el-link>
                </template>
              </el-table-column>
              <el-table-column label="活动" prop="activltynum">
                <template slot-scope="scope">
                  <el-link type="primary" @click="activityInfo(scope.row.id)">{{
                    scope.row.activltynum
                  }}</el-link>
                </template>
              </el-table-column>
              <el-table-column label="文章" prop="articlenum">
                <template slot-scope="scope">
                  <el-link @click="articleInfo(scope.row.id)" type="primary">{{
                    scope.row.articlenum
                  }}</el-link>
                </template>
              </el-table-column>
              <el-table-column label="排序" prop="sort"></el-table-column>
              <el-table-column align="center" width="330px" label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    v-if="isshoweditbtn"
                    @click="handleEdit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    v-if="isshowdelbtn"
                    @click="handleDelete(scope.row)"
                    >删除</el-button
                  >
                  <el-button
                    size="mini"
                    v-if="isshofenpeilbtn"
                    type="primary"
                    @click="handleLimit(scope.row)"
                    >分配额度</el-button
                  >
                  <el-button
                    size="mini"
                    v-if="isshowedulbtn"
                    type="primary"
                    @click="handleCheck(scope.row)"
                    >查看申请</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.pageNum"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑社团弹出层 -->
    <el-dialog title="编辑社团" :visible.sync="showeditdialog" width="30%">
      <!-- :rules="lineseditrules" -->
      <el-form ref="editform" :model="lineeditdata" label-width="80px">
        <el-form-item label="社团名称:" prop="name">
          <el-input v-model="lineeditdata.name"></el-input>
        </el-form-item>
        <el-form-item label="社团简称" prop="shortName">
          <el-input v-model="lineeditdata.shortName"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="shortName">
          <el-input v-model="lineeditdata.sort"></el-input>
        </el-form-item>
        <el-form-item label="社团logo">
          <el-upload
            style="display: flex; align-items: center"
            action="/web/upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :class="uploadDisabled ? 'isshowimg' : ''"
            :limit="1"
            :on-change="handleChange"
            :http-request="httpRequest"
            :file-list="lineeditdata.oneimgList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img width="100%" :src="lineeditdata.logo" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceleditdialog">取 消</el-button>
        <el-button type="primary" @click="addeditBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分配额度弹窗 -->
    <el-dialog title="分配额度" :visible.sync="showlimitialog" width="30%">
      <el-form ref="limitform" :model="limitdata" label-width="100px">
        <el-form-item label="额度（小时）:" prop="num">
          <el-input v-model="limitdata.num"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelimitdialog">取 消</el-button>
        <el-button type="primary" @click="addlimitBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AdminClub",
  props: {},
  components: {},
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
        search: "",
      },
      dialogVisible: false, // 预览弹窗
      showeditdialog: false,
      lineeditdata: {
        id: "",
        name: "",
        shortName: "",
        sort: "",
        logo: "",
        oneimgList: [],
        // uploadDisabled: false,
      }, // 社团编辑
      uploadDisabled: false,
      showlimitialog: false, // 分配额度弹窗
      limitdata: {
        // 额度弹窗
        id: "",
        num: 0,
      },
      isshowlistbtn: true,
      isshoweditbtn: true,
      isshowaddbtn: true,
      isshowdelbtn: true,
      isshowedulbtn: true,
      isshofenpeilbtn: true,
    };
  },
  created() {
    this.getData();
  },
  // 人员管理,人员查看,人员编辑,人员删除,活动管理,活动查看,活动新增,活动编辑,活动删除,文章管理,文章查看,文章新增,文章编辑,文章删除,
  // 部门管理,部门查看,部门新增,部门编辑,部门删除,行业分类,行业分类查看,行业分类新增,行业分类编辑,行业分类删除,
  // 梨友分类,梨友分类查看,梨友分类新增,梨友分类编辑,梨友分类删除,Banner管理,Banner管理查看,Banner管理新增,Banner管理编辑,Banner管理删除,
  // 官方活动,官方活动查看,官方活动新增,官方活动编辑,官方活动删除,精选推荐,精选推荐查看,精选推荐新增,精选推荐编辑,精选推荐删除,
  // 平台管理员,平台管理员查看,平台管理员新增,平台管理员编辑,平台管理员删除,普通用户,普通用户查看,普通用户删除,
  // 职务管理,职务管理查看,职务管理新增,职务管理编辑,职务管理删除,梨友需求管理,梨友需求查看,梨友需求编辑,额度管理,额度列表,额度分配,额度申请,额度审核,
  // 社团管理,社团查看,社团编辑,社团新增,社团删除
  mounted() {
    if (String(localStorage.getItem("perlist").indexOf("社团查看")) == -1) {
      this.isshowlistbtn = false;
    }
    if (String(localStorage.getItem("perlist").indexOf("社团编辑")) == -1) {
      this.isshoweditbtn = false;
    }
    if (String(localStorage.getItem("perlist").indexOf("社团新增")) == -1) {
      this.isshowaddbtn = false;
    }
    if (String(localStorage.getItem("perlist").indexOf("社团删除")) == -1) {
      this.isshowdelbtn = false;
    }

    if (String(localStorage.getItem("perlist").indexOf("额度列表")) == -1) {
      this.isshowedulbtn = false;
    }
    if (String(localStorage.getItem("perlist").indexOf("额度分配")) == -1) {
      this.isshofenpeilbtn = false;
    }
  },
  methods: {
    handleEdit(row) {
      let id = row.id;
      this.lineeditdata.id = id;
      this.lineeditdata.name = row.name;
      this.lineeditdata.shortName = row.shortName;
      this.lineeditdata.sort = row.sort;
      this.lineeditdata.logo = this.imgurl + row.logo;
      this.lineeditdata.severurl = row.logo;
      let oneimg = [{ url: this.imgurl + row.logo }];
      this.lineeditdata.oneimgList = oneimg;
      if (row.logo && row.logo != null) {
        this.uploadDisabled = true;
      }
      this.showeditdialog = true;
    },

    // 人员详情
    personInfo(id) {
      if (String(localStorage.getItem("perlist").indexOf("人员查看")) == -1) {
        this.$message.error("您没有此权限");
        return false;
      }
      this.$router.push({
        path: "/adminClubUser/",
        query: {
          id: id,
        },
      });
    },

    // 部门详情
    partInfo(id) {
      if (String(localStorage.getItem("perlist").indexOf("部门查看")) == -1) {
        this.$message.error("您没有此权限");
        return false;
      }
      this.$router.push({
        path: "/adminClubPart/",
        query: {
          id: id,
        },
      });
    },

    // 活动详情  活动管理,活动新增,活动编辑,活动删除
    activityInfo(id) {
      let perlist = localStorage.getItem("perlist");
      perlist = perlist.split(",");
      if (perlist.indexOf("活动查看") > -1) {
      } else {
        this.$message.error("您没有此权限");
        return false;
      }
      this.$router.push({
        path: "/adminClubActivity/",
        query: {
          id: id,
        },
      });
    },

    // 文章详情
    articleInfo(id) {
      if (String(localStorage.getItem("perlist").indexOf("文章查看")) == -1) {
        this.$message.error("您没有此权限");
        return false;
      }
      this.$router.push({
        path: "/adminClubArticle/",
        query: {
          id: id,
        },
      });
    },

    // 查看申请
    handleCheck(row) {
      this.$router.push({
        path: "/admincheck/",
        query: {
          id: row.id,
        },
      });
    },

    // 取消编辑
    canceleditdialog() {
      this.showeditdialog = false;
      this.lineeditdata = {
        id: "",
        name: "",
        shortName: "",
        logo: "",
        oneimgList: [],
      };
      this.$refs.editform.resetFields();
    },

    // 确认编辑
    addeditBtn() {
      if (!this.lineeditdata.shortName) {
        this.$message.error("请输入社团简称");
        return false;
      }
      let formdata = new FormData();
      formdata.append("club_id", this.lineeditdata.id);
      formdata.append("name", this.lineeditdata.name);
      formdata.append("shortName", this.lineeditdata.shortName);
      formdata.append("sort", this.lineeditdata.sort);
      formdata.append("logo", this.lineeditdata.severurl);
      this.$axios({
        url: "/backend/club_change/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.getData();
            this.showeditdialog = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    // 清除page重载列表
    clearPageGetData() {
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios({
        url: "/web/getAdminClubList/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 删除
    handleDelete(row) {
      let id = row.id;
      let club_ids = [];
      club_ids.push(id);
      let formdata = new FormData();
      formdata.set("club_ids", JSON.stringify(club_ids));
      this.$confirm("是否删除此社团?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/club_delete/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.$message.success("操作成功");
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 图片上传
    httpRequest(item) {
      // 自定义文件上传
      // var up = new File([item.file], item.file.name, {
      //   type: item.file.type
      // });
      const fd = new FormData();
      fd.set("file", item.file);
      this.$axios({
        url: "/web/upload/",
        method: "post",
        data: fd,
      })
        .then((res) => {
          if (res.data.success) {
            this.severurl = res.data.imgurl;
            this.lineeditdata.severurl = res.data.imgurl;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 图片更换
    handleChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.lineeditdata.logo = URL.createObjectURL(file.raw);
      if (fileList.length >= 1) {
        this.uploadDisabled = true;
      }
    },

    // 图片删除
    handleRemove(file, fileList) {
      this.uploadDisabled = false;
      this.imageUrl = "";
      this.lineeditdata.severurl = "";
      this.lineeditdata.logo = "";
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },

    // 分配额度
    handleLimit(row) {
      let id = row.id;
      this.limitdata.id = id;
      // this.limitdata.num = row.lines;
      this.showlimitialog = true;
    },

    // 取消额度弹窗
    cancelimitdialog() {
      this.showlimitialog = false;
      this.$refs.limitform.resetFields();
    },

    // 确认添加额度
    addlimitBtn() {
      let formdata = new FormData();
      formdata.set("club_id", this.limitdata.id);
      formdata.set("num", this.limitdata.num);
      formdata.set("action", "allot");
      // formdata.set("content", this.linesdata.reason);
      this.$axios({
        url: "/backend/create_apply/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.showlimitialog = false;
            this.limitdata = {
              id: "",
              num: "",
            };
            this.getData();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 新增社团
    addAdminClub() {
      this.$router.push({
        path: "/addAdminClub",
      });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-operate {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.isshowimg /deep/ .el-upload {
  display: none;
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
.info-breadcrumb {
  margin-top: 10px;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
// 搜索框
.search_box {
  width: 25%;
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}

.el-image__inner {
  object-fit: cover;
}
</style>
