import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Information from '../views/Information.vue'
import Club from '../views/Club.vue'
import ClubUser from '../views/ClubUser.vue'
import ClubUserInfo from '../views/ClubUserInfo.vue'
import ActivityInfo from '../views/ActivityInfo.vue'
import ArticleInfo from '../views/ArticleInfo.vue'
import Activity from '../views/Activity.vue'
import Article from '../views/Article.vue'
import Part from '../views/Part.vue'
import AddActivity from '../views/AddActivity.vue'
import AddPart from '../views/AddPart.vue'
import PartInfo from '../views/PartInfo.vue'
import Limit from '../views/Limit.vue'
import AddArticle from '../views/AddArticle.vue'
import AdminClub from '../views/AdminClub.vue'
import AddAdminClub from '../views/Addadminclub.vue'
import AdminClubUserList from '../views/AdminClubUserList.vue'
import AdminClubPart from '../views/AdminClubPart.vue'
import AdminClubActivity from '../views/AdminClubActivity.vue'
import AdminClubArticle from '../views/AdminClubArticle.vue'
import AddAdminClubUser from '../views/Addadminclubuser.vue'
import AdminLimitCheck from '../views/AdminLimitCheck.vue'
import AdminClubArticleInfo from '../views/AdminClubArticleInfo.vue'
import AdminActivityInfo from '../views/AdminActivityInfo.vue'
import AdminClubUserInfo from '../views/AdminClubUserInfo.vue'
import AdminHome from '../views/AdminHome.vue'
import AddBanner from '../views/AddBanner.vue'
import AddOfficialAc from '../views/AddOfficialAc.vue'
import AddSelectRecd from '../views/AddSelectRecd.vue'
import BannerInfo from '../views/BannerInfo.vue'
import OfficialAcInfo from '../views/OfficialAcInfo.vue'
import SelectRecdInfo from '../views/SelectRecdInfo.vue'
import Needs from '../views/Needs.vue'
import AdminUser from '../views/AdminUser.vue'
import AddManage from '../views/AddManage.vue'
import NeedsInfo from '../views/NeedsInfo.vue'
import Job from '../views/Job.vue'
import Business from '../views/Business.vue'
import BusinessInfo from '../views/BusinessInfo.vue'
import AddBusiness from '../views/AddBusiness.vue'
import AdminBusiness from '../views/AdminBusiness.vue'
import AdminBusinessInfo from '../views/AdminBusinessInfo.vue'
import ApplyInfo from '../views/ApplyInfo.vue'
import AdminUserInfo from '../views/AdminUserInfo.vue'

Vue.use(VueRouter)

const routes = [
  {  // 登录页
    path: '/',
    name: 'Login',
    component: Login
  },
  { // 首页
    path: '/index',
    name: 'Home',
    component: Home
  },
  {  // 基础信息页面（平台管理员、社团管理员等）
    path: '/information',
    name: 'Information',
    component: Information
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {  // 社团管理员社团首页
    path: '/club',
    name: 'Club',
    component: Club,
  },
  {  // 社团管理员人员列
    path: '/club/user',
    name: 'ClubUser',
    component: ClubUser,
  },
  {  // 社团管理员活动列
    path: '/club/activity',
    name: 'Activity',
    component: Activity
  },
  {  // 社团管理员文章列
    path: '/club/article',
    name: 'Article',
    component: Article
  },
  { // 社团管理员部门列
    path: '/club/part',
    name: 'Part',
    component: Part
  },
  { // 社团管理员部门列
    path: '/club/job',
    name: 'Job',
    component: Job
  },
  { // 社团管理员人员详情
    path: '/club/clubuserinfo',
    name: 'ClubUserInfo',
    component: ClubUserInfo
  },
  {  // 社团管理员活动详情
    path: '/club/activityInfo',
    name: 'ActivityInfo',
    component: ActivityInfo
  },
  {  // 社团管理员新增活动
    path: '/club/addActivity',
    name: 'AddActivity',
    component: AddActivity
  },
  { // 社团管理员新增部门
    path: '/club/addPart',
    name: 'AddPart',
    component: AddPart
  },
  {  // 社团管理员部门详情
    path: '/club/partInfo',
    name: 'PartInfo',
    component: PartInfo
  },
  { // 社团管理员额度记录
    path: '/club/limit',
    name: 'Limit',
    component: Limit
  },
  { // 社团管理员新增文章
    path: '/club/addArticle',
    name: 'AddArticle',
    component: AddArticle
  },
  {  // 社团管理员文章详情
    path: '/club/clubarticleinfo',
    name: 'ArticleInfo',
    component: ArticleInfo
  },
  { // 平台管理员社团管理
    path: '/adminClub',
    name: 'AdminClub',
    component: AdminClub
  },
  {  // 平台管理员新增社团
    path: '/addAdminClub',
    name: 'AddAdminClub',
    component: AddAdminClub
  },
  { // 平台管理员社团用户
    path: '/adminClubUser',
    name: 'AdminClubUserList',
    component: AdminClubUserList
  },
  { // 平台管理员社团部门列
    path: '/adminClubPart',
    name: 'AdminClubPart',
    component: AdminClubPart
  },
  { // 平台管理员社团活动列
    path: '/adminClubActivity',
    name: 'AdminClubActivity',
    component: AdminClubActivity
  },
  { // 平台管理员社团文章列
    path: '/adminClubArticle',
    name: 'AdminClubArticle',
    component: AdminClubArticle
  },
  { // 平台管理员新增社团人员
    path: '/addAdminClubUser',
    name: 'AddAdminClubUser',
    component: AddAdminClubUser
  },
  {  // 平台管理员社团查看申请额度
    path: '/admincheck',
    name: 'AdminLimitCheck',
    component: AdminLimitCheck
  },
  {  // 平台管理员社团文章详情
    path: '/adminarticleinfo',
    name: 'AdminClubArticleInfo',
    component: AdminClubArticleInfo
  },
  { // 平台管理员社团活动详情
    path: '/adminactivityInfo',
    name: 'AdminActivityInfo',
    component: AdminActivityInfo
  },
  { // 平台管理员社团用户详情
    path: '/adminclubuserinfo',
    name: 'AdminClubUserInfo',
    component: AdminClubUserInfo
  },
  {
    path: '/adminUserInfo',
    name: 'AdminUserInfo',
    component: AdminUserInfo
  },
  { // 首页管理
    path: '/adminhome/',
    name: 'AdminHome',
    component: AdminHome  
  },
  {  // 新增banner
    path: '/addbanner/',
    name: 'AddBanner',
    component: AddBanner
  },
  {  // 新增官方活动
    path: '/addofficialac/',
    name: 'AddOfficialAc',
    component: AddOfficialAc
  },
  { // 新增精选推荐
    path: '/addselectrecd/',
    name: 'AddSelectRecd',
    component: AddSelectRecd
  },
  { // banner 详情
    path: '/bannerinfo/',
    name: 'BannerInfo',
    component: BannerInfo
  },
  {  // 官方活动详情
    path: '/officialacinfo/',
    name: 'OfficialAcInfo',
    component: OfficialAcInfo
  },
  { // 精选推荐详情
    path: '/selectrecdinfo/',
    name: 'SelectRecdInfo',
    component: SelectRecdInfo
  },
  {  // 需求列
    path: '/needs/',
    name: 'Needs',
    component: Needs
  },
  {  // 用户管理
    path: '/adminuser/',
    name: 'AdminUser',
    component: AdminUser
  },
  {  // 新增平台管理员
    path: '/addmanage/',
    name: 'AddManage',
    component: AddManage
  },
  {  // 需求详情
    path: '/needsinfo/',
    name: 'NeedsInfo',
    component: NeedsInfo
  },
  {  // 业务介绍
    path: '/business/',
    name: Business,
    component: Business
  },
  {  // 业务介绍详情
    path: '/businessinfo/',
    name: BusinessInfo,
    component: BusinessInfo
  },
  { // 新增业务介绍
    path: '/addbusiness/',
    name: 'AddBusiness',
    component: AddBusiness
  },
  { // 平台业务介绍
    path: '/adminbusiness/',
    name: 'AdminBusiness',
    component: AdminBusiness
  },
  { // 平台业务介绍详情
    path: '/adminbusinessinfo/',
    name: 'AdminBusinessInfo',
    component: AdminBusinessInfo
  },
  {
    path: '/applyinfo/',
    name: 'ApplyInfo',
    component: ApplyInfo
  }

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let userid = localStorage.getItem('userid')
  if(userid) {
    next()
  } else {
    if(to.path == '/') {
      next()
    } else {
      next('/')
    }
  }
})

export default router
