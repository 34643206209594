<template>
  <div class="info-table">
        <div class="search_box">
          <el-input
            placeholder="请输入姓名"
            @clear="clearPageGetData"
            v-model="pageData.search"
            clearable
          >
          </el-input>
          <el-button
            style="margin-left: 20px"
            type="primary"
            @click="clearPageGetData"
            icon="el-icon-search"
            >搜索</el-button
          >
        </div>
    <template>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column label="ID" prop="id" width="100">
          <!-- <template slot-scope="scope">
            <el-link
              type="primary"
              @click="bannerInfo(scope.row.id)"
              >{{ scope.row.id }}</el-link
            >
          </template> -->
        </el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="学号" prop="StuNo"></el-table-column>
        <el-table-column label="入学年份" prop="grade"></el-table-column>
        <el-table-column label="专业" prop="className"></el-table-column>
        <el-table-column label="邀请码" prop="qrcode"></el-table-column>
        <el-table-column label="操作" align="center" width="280">
          <template slot-scope="scope">
            <el-button
              v-if="isshowdelbtn"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.is_path"
              size="mini"
              type="primary"
              @click="handleCheck(scope.row)"
              >邀请路径</el-button
            >
            <el-button
              v-if="scope.row.is_record"
              size="mini"
              type="primary"
              @click="handleHistory(scope.row)"
              >邀请记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="info-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageData.limit"
        @current-change="handleCurrentChange"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 路径查看弹出层 -->
    <el-dialog title="邀请路径" :visible.sync="dialogVisible" width="30%">
      <div class="block">
        <el-timeline v-if="invitedata.length > 0">
          <el-timeline-item
            v-for="(item, index) in invitedata"
            :key="index"
            :timestamp="item.invite_date"
            placement="top"
          >
            <el-card>
              <h4>{{ item.inviteuser }}邀请{{item.user}}加入</h4>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else>
          <h4>于{{ createtime }}加入</h4>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 邀请记录弹出层 -->
    <el-dialog title="邀请记录" :visible.sync="historyVisible" width="30%">
      <div class="block">
        <el-timeline v-if="historydata.length > 0">
          <el-timeline-item
            v-for="(item, index) in historydata"
            :key="index"
            :timestamp="item.invite_date"
            placement="top"
          >
            <el-card>
              <h4>邀请{{ item.name }}加入</h4>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else>
          <h4>无邀请记录</h4>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="historyVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      tableData: [],
      totalNum: 0,
      pageData: {
        page: 1,
        limit: 10,
      },
      dialogVisible: false,
      invitedata: [],
      createtime: "",
      historyVisible: false,
      historydata: [],
      isshowdelbtn: true,
    };
  },
  created() {},
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('普通用户删除')) == -1) {
      this.isshowdelbtn = false
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.page = currentpage;
      this.getData();
    },

    // 清除page重载列表
    clearPageGetData(){
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios
        .get("/backend/user_list/", { params: this.pageData })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 删除banner
    handleDelete(e) {
      let id = e.id;
      let user_ids = [];
      user_ids.push(id);
      let formdata = new FormData();
      formdata.append("ids", JSON.stringify(user_ids));
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/user_delete/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 查看邀请路径
    handleCheck(row) {
      let id = row.id;
      this.$axios({
        url: "/backend/user_view_invite/",
        method: "get",
        params: {
          id: id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.dialogVisible = true;
            this.invitedata = res.data.data;
            this.createtime = res.data.create_time;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 查看邀请记录
    handleHistory(row) {
      let id = row.id;
      this.$axios({
        url: "/backend/invite_view_user/",
        method: "get",
        params: {
          id: id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.historyVisible = true;
            this.historydata = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 新增banner
    addBanner() {
      this.$router.push("/addbanner/");
    },
  },
};
</script>

<style lang="less" scoped>
// 搜索框
.search_box {
  width: 25%;
  display: flex;
  align-items: center;
  margin-bottom: 20px !important;
}

.information .info-container .info-table {
  margin-top: 5px !important;
}
</style>
