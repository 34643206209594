<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text">活动</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        "
      >
      <div class="search_box">
        <el-input
          placeholder="请输入活动标题"
          @clear="clearPageGetData"
          v-model="pageData.search"
          clearable
        >
        </el-input>
        <div style="display: flex; align-items: flex-start; margin-left: 12px">
          <div class="block">
            <el-date-picker
              v-model="timevalue"
              type="daterange"
              range-separator="至"
              start-placeholder="活动创建开始日期"
              end-placeholder="活动创建结束日期"
              value-format="yyyy-MM-dd"
              @change="getTimePeriod"
            >
            </el-date-picker>
          </div>
        </div>
        <el-button
          style="margin-left: 20px"
          type="primary"
          @click="clearPageGetData"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div class="info-operate">
        <el-button type="primary" v-if="isshowaddbtn" @click="exportContent"
          >导出</el-button
        >
        <el-button type="primary" v-if="isshowaddbtn" @click="addActivity"
          >新增</el-button
        >
        <!-- <el-button type="danger">删除</el-button> -->
      </div>
      </div>

      <div class="info-tab">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection"></el-table-column>
              <el-table-column label="ID" prop="id" width="80">
              </el-table-column>
              <el-table-column label="标题" prop="title"></el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image
                    style="height: 80px; width: 100%"
                    :src="imgurl + scope.row.cover"
                  >
                    <div
                      slot="error"
                      class="image-slot"
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        src="../assets/image/defaultImg.png"
                        alt=""
                        style="
                          object-fit: cover;
                          width: 50%;
                          height: 50%;
                          opacity: 0.8;
                        "
                      />
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="活动时间">
                <template slot-scope="scope">
                  {{ scope.row.activityStartDate }}-{{
                    scope.row.activityEndDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="地点" prop="address"></el-table-column>
              <el-table-column
                label="报名截止时间"
                prop="enrollDate"
              ></el-table-column>
              <el-table-column label="部门" prop="publisher"></el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <div>{{ scope.row.isonline ? "已上线" : "已下线" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="活动状态">
                <template slot-scope="scope">
                  <div>{{ scope.row.status == 5 ? "已结算" : "未结算" }}</div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="类型"
                prop="type"
                width="130"
              ></el-table-column> -->
              <el-table-column label="报名人数" prop="enrollnum">
                <template slot-scope="scope">
                  <el-link type="primary" @click="enroolNumBtn(scope.row)">{{
                    scope.row.enrollnum
                  }}</el-link>
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                prop="create_date"
              ></el-table-column>
              <el-table-column align="center" label="操作" width="240">
                <template slot-scope="scope">
                  <el-button
                    v-if="isshoweditbtn"
                    size="mini"
                    type="primary"
                    @click="handleInfo(scope.row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="!isshoweditbtn"
                    size="mini"
                    type="primary"
                    @click="handleInfo(scope.row.id)"
                    >查看</el-button
                  >
                  <el-button
                    v-if="isshoweditbtn"
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >{{ scope.row.isonline ? "下线" : "上线" }}</el-button
                  >
                  <el-button
                    v-if="isshoweditbtn"
                    size="mini"
                    type="primary"
                    @click="handleCancel(scope.row)"
                    >取消活动</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.pageNum"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 报名人数弹出层 -->
    <el-dialog
      title="报名人员"
      :visible.sync="enrooldialog"
      @close="dialogClose"
    >
      <enrool-list
        @endata="updateEnroolData"
        :data="enrollTableData"
        @sign="handleSign"
        @distribution="distributionTime"
        :enroolData="enroolData"
        :totalNum="enrolTotalNum"
        :isoverdue="isoverdue"
      ></enrool-list>
    </el-dialog>
  </div>
</template>

<script>
import EnroolList from "../components/EnroolList.vue";
import { mapState } from "vuex";
export default {
  components: { EnroolList },
  name: "Activity",
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
        search: "",
        starttime:"",
        endtime:"",
      },
      enroolData: {
        id: "",
        currentPage: 1,
        pageNum: 5,
        search: "",
      },
      enrooldialog: false, // 报名人数组件
      enrollTableData: [],
      enrolTotalNum: 0, // 报名人员总数
      isoverdue: false, // 活动是否过期
      isshoweditbtn: true,
      isshowaddbtn: true,

      // 开始时间至结束时间
      timevalue: [],
      // 导出的data
      exportData:{
        clubid:this.clubid,
        deptid:"",
        search:"",
        starttime:"",
        endtime:"",
      }
    };
  },
  created() {
    this.clubid = this.$route.query.id;
    this.pageData.id = this.$route.query.id;
    this.getData();
  },

  mounted() {
    let perlist = localStorage.getItem("perlist");
    perlist = perlist.split(",");
    if (perlist.indexOf("活动编辑") > -1) {
    } else {
      this.isshoweditbtn = false;
    }
    if (perlist.indexOf("活动新增") > -1) {
    } else {
      this.isshowaddbtn = false;
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    // 活动详情
    handleInfo(id) {
      this.$router.push({
        path: "/club/activityInfo",
        query: {
          id: id,
          clubid: this.clubid,
        },
      });
    },

    // 清除page重载列表
    clearPageGetData() {
      this.pageData.page = 1;
      this.getData();
    },

    getTimePeriod(val) {
      if (val) {
        this.pageData.starttime = val[0];
        this.pageData.endtime = val[1];
      } else {
        this.pageData.starttime = "";
        this.pageData.endtime = "";
      }
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      // console.log(this.timevalue[0],this.timevalue[1]);
      this.$axios({
        url: "/web/getClubActivity/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 上下线
    handleDelete(e, row) {
      let id = row.id;
      let activity_id = [];
      activity_id.push(id);
      let isonline = row.isonline;
      let status = 0;
      if (isonline) {
        status = 1;
      } else {
        status = 0;
      }
      let formdata = new FormData();
      formdata.set("id", JSON.stringify(activity_id));
      formdata.set("status", status);
      this.$axios({
        url: "/backend/oper_club_activity/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("操作成功");
            this.getData();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 取消活动
    handleCancel(row) {
      let id = row.id;
      let activity_id = [];
      activity_id.push(id);
      this.$confirm("是否取消该活动?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.set("id", JSON.stringify(activity_id));
          formdata.set("status", 2);
          this.$axios({
            url: "/backend/oper_club_activity/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.$message.success("操作成功");
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    // 新增活动
    addActivity() {
      this.$router.push({
        path: "/club/addActivity",
        query: {
          clubid: this.clubid,
        },
      });
    },

    // 导出
    exportContent() {
      // 这边type=2再传一个部门id
      var str = '';
      if (localStorage.getItem("usertype")==2) {
        this.exportData.deptid=localStorage.getItem("department_id");
        str='&deptid='+localStorage.getItem("department_id");
      }

      window.open('https://lhs.gazrey.com/web/outputClubActivity/?search='+this.pageData.search+'&starttime='+this.pageData.starttime+'&endtime='+this.pageData.endtime+'&clubid='+this.clubid+str);

      //   // responseType: 'blob',   // 需要在此处设置请求头，设置请求的类型为blob文件流的形式

    },

    // 弹窗关闭
    dialogClose() {
      this.enroolData = {
        id: "",
        currentPage: 1,
        pageNum: 5,
        search: "",
      };
    },

    // 报名人数点击
    enroolNumBtn(e) {
      if (!this.isshoweditbtn) {
        this.$message.error("您没有此权限");
        return false;
      }
      this.enroolData.id = e.id;
      this.$axios({
        url: "/web/getEnroolList/",
        method: "get",
        params: this.enroolData,
      })
        .then((res) => {
          if (res.data.success) {
            this.enrollTableData = res.data.data;
            this.enrolTotalNum = res.data.total;
            this.isoverdue = res.data.isoverdue;
            this.enrooldialog = true;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 子组件
    updateEnroolData(pdata) {
      this.$axios({
        url: "/web/getEnroolList/",
        method: "get",
        params: pdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.enrollTableData = res.data.data;
            this.enrolTotalNum = res.data.total;
            this.isoverdue = res.data.isoverdue;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 签到
    handleSign(e) {
      let enrollTableData = this.enrollTableData;
      let data = e; // index id
      let index = data.index;
      let userid = data.row.id; // 用户id
      let id = this.enroolData.id; // 活动id
      let formdata = new FormData();
      formdata.append("user_id", userid);
      formdata.append("activity_id", id);
      formdata.append("action", "sign");
      this.$axios({
        url: "/backend/activity_sign/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            enrollTableData[index].state = 2;
            enrollTableData[index].signDate = res.data.time;
            this.enrollTableData = enrollTableData;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 分配时长
    distributionTime(e) {
      let enrollTableData = this.enrollTableData;
      let data = e; // index id
      let index = data.index;
      let userid = data.row.id; // 用户id
      let id = this.enroolData.id; // 活动id
      let formdata = new FormData();
      formdata.append("user_id", userid);
      formdata.append("activity_id", id);
      formdata.append("action", "allot");
      this.$axios({
        url: "/backend/activity_sign/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            enrollTableData[index].state = 6;
            enrollTableData[index].signDate = res.data.time;
            this.enrollTableData = enrollTableData;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-operate {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

// 搜索框
.search_box {
  margin-top: 40px;
  // width: 25%;
  width: 51%;
  display: flex;
  align-items: center;
}
.block /deep/ .el-date-editor--daterange.el-input__inner {
  width: 400px;
  margin-left: 8px;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
