<template>
  <div class="info-table" v-if="isshowlistbtn">
    <!-- <div class="info-operate" style="margin-bottom: 20px">
      <el-button type="primary" v-if="isshowaddbtn" @click="addOfficialAc">新增</el-button>
    </div> -->
    <template>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column label="ID" prop="id" width="100">
        </el-table-column>
        <el-table-column
          label="标题"
          prop="title"
        ></el-table-column>
        <el-table-column
          label="类型"
          prop="type"
        ></el-table-column>
        <el-table-column
          label="排序"
          prop="sort"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="activityStartDate"
        ></el-table-column>
        <el-table-column
          label="结束时间"
          prop="activityEndDate"
        ></el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <div class="btns">
              <el-button
                size="mini"
                v-if="isshoweditbtn"
                type="primary"
                @click="officialAcInfo(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                v-if="!isshoweditbtn"
                type="primary"
                @click="officialAcInfo(scope.row.id)"
                >查看</el-button
              >
              <el-button
                size="mini"
                v-if="isshowdelbtn"
                type="danger"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </div>
            
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="info-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageData.limit"
        @current-change="handleCurrentChange"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfficialAc",
  data() {
    return {
      tableData: [],
      totalNum: 0,
      pageData: {
        page: 1,
        limit: 10,
      },
      isshowlistbtn: true,
      isshowaddbtn: true,
      isshoweditbtn: true,
      isshowdelbtn: true
    };
  },
  created() {},
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('官方活动查看')) == -1) {
      this.isshowlistbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('官方活动新增')) == -1) {
      this.isshowaddbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('官方活动编辑')) == -1) {
      this.isshoweditbtn = false
    }
    if(String(localStorage.getItem('perlist').indexOf('官方活动删除')) == -1) {
      this.isshowdelbtn = false
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.page = currentpage;
      this.getData();
    },

    getData() {
      this.$axios
        .get("/backend/official_activity_list/", { params: this.pageData })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 官方活动详情
    officialAcInfo(id) {
      this.$router.push({
        path: '/officialacinfo/',
        query: {
          'id': id
        }
      })
    },

    // 删除官方活动
    handleDelete(e) {
      let id = e.id;
      let ids = [];
      ids.push(id);
      let formdata = new FormData();
      formdata.append("ids", JSON.stringify(ids));
      this.$confirm("是否删除此官方活动?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/official_activity_delete/",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              if (res.data.success) {
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 新增官方活动
    addOfficialAc() {
      this.$router.push("/addOfficialAc/");
    },
  },
};
</script>

<style lang="less" scoped>
.btns{
  display: flex;
}
</style>