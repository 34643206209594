<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text">文章</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="search_box">
        <el-input
          placeholder="请输入文章标题"
          @clear="clearPageGetData"
          v-model="pageData.search"
          clearable
        >
        </el-input>
        <el-button
          style="margin-left: 20px"
          type="primary"
          @click="clearPageGetData"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div class="info-operate">
        <el-button type="primary" v-if="isshowaddbtn" @click="addArticle"
          >新增</el-button
        >
        <!-- <el-button type="danger">删除</el-button> -->
      </div>
      <div class="info-tab">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="100"></el-table-column>
              <el-table-column label="ID" width="100" prop="id">
              </el-table-column>
              <el-table-column label="标题" prop="title"></el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image
                    style="height: 80px; width: 100%"
                    :src="imgurl + scope.row.cover"
                    ><div
                      slot="error"
                      class="image-slot"
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        src="../assets/image/defaultImg.png"
                        alt=""
                        style="
                          object-fit: cover;
                          width: 50%;
                          height: 50%;
                          opacity: 0.8;
                        "
                      /></div
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="发布时间"
                prop="create_date"
              ></el-table-column>
              <el-table-column label="部门" prop="publisher"></el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.is_online == 1 ? "已上线" : "已下线" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="是否置顶">
                <template slot-scope="scope">
                  <div>{{ scope.row.is_top == 1 ? "否" : "置顶" }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="handleInfo(scope.row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="!isshoweditbtn"
                    size="mini"
                    type="primary"
                    @click="handleInfo(scope.row.id)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >{{ scope.row.is_online == 1 ? "下线" : "上线" }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.pageNum"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Article",
  computed: {
    ...mapState(["imgurl"]),
  },
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        currentPage: 1,
        pageNum: 10,
        search: "",
      },
      isshoweditbtn: true,
      isshowaddbtn: true,
    };
  },
  created() {
    this.clubid = this.$route.query.id;
    this.pageData.id = this.$route.query.id;
    this.getData();
  },
  mounted() {
    if (String(localStorage.getItem("perlist").indexOf("文章编辑")) == -1) {
      this.isshoweditbtn = false;
    }
    if (String(localStorage.getItem("perlist").indexOf("文章新增")) == -1) {
      this.isshowaddbtn = false;
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (currentpage) {
      this.pageData.currentPage = currentpage;
      this.getData();
    },

    // 文章详情
    handleInfo(id) {
      this.$router.push({
        path: "/club/clubarticleinfo",
        query: {
          id: id,
          clubid: this.clubid,
        },
      });
    },

    // 清除page重载列表
    clearPageGetData() {
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios({
        url: "/web/getClubArticle/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 新增文章
    addArticle() {
      this.$router.push({
        path: "/club/addArticle",
        query: {
          clubid: this.clubid,
        },
      });
    },

    // 删除
    handleDelete(e, row) {
      let id = row.id;
      let article_id = [];
      article_id.push(id);
      let isonline = row.is_online;
      let status = 0;
      if (isonline) {
        status = 1;
      } else {
        status = 0;
      }
      let formdata = new FormData();
      formdata.set("article_id", JSON.stringify(article_id));
      formdata.set("action", status);
      this.$axios({
        url: "/backend/oper_club_article/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("操作成功");
            this.getData();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

// 搜索框
.search_box {
  margin-top: 40px;
  width: 25%;
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
