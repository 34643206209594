<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >社团人员</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="search_box">
        <el-input
          placeholder="请输入姓名"
          @clear="clearPageGetData"
          v-model="pageData.search"
          clearable
        >
        </el-input>
        <el-button
          style="margin-left: 20px"
          type="primary"
          @click="clearPageGetData"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div class="info-tab">
        <div class="info-table">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="100"></el-table-column>
              <el-table-column label="ID" width="100" prop="id">
              </el-table-column>
              <el-table-column label="姓名" prop="name"></el-table-column>
              <el-table-column label="入学年份" prop="grade"></el-table-column>
              <el-table-column label="专业" prop="classname"></el-table-column>
              <el-table-column
                label="联系方式"
                prop="phoneNum"
              ></el-table-column>
              <el-table-column
                label="职务"
                prop="position_name"
              ></el-table-column>
              <el-table-column label="部门" prop="part"></el-table-column>
              <el-table-column
                label="加入时间"
                prop="create_time"
              ></el-table-column>
              <el-table-column label="是否是管理员" prop="part">
                <template slot-scope="scope">
                  <div>{{ scope.row.is_admin ? "是" : "否" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="userTime(scope.row.id)"
                    >公益时长</el-button
                  >
                  <el-button
                    v-if="isshoweditbtn"
                    size="mini"
                    type="primary"
                    @click="userInfo(scope.row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="!isshoweditbtn"
                    size="mini"
                    type="primary"
                    @click="userInfo(scope.row.id)"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="info-pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageData.limit"
              @current-change="handleCurrentChange"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 公益时长弹出层 -->
    <el-dialog title="公益时长" :visible.sync="dialogVisible" width="30%">
      <div class="block">
        <el-timeline v-if="data.length > 0">
          <el-timeline-item
            v-for="(item, index) in data"
            :key="index"
            :timestamp="item.date"
            placement="top"
          >
            <el-card>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <div>
                  <h4>活动名称：{{ item.activityName }}</h4>
                  <h4 style="margin-top: 10px">
                    公益时长：{{ item.time }}小时
                  </h4>
                </div>
                <div>
                  <el-button @click="checkCertf(item)" type="primary"
                    >查看证书</el-button
                  >
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else>暂无证书</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 证书 -->
    <el-dialog title="证书" :center="true" :visible.sync="showcert" width="26%">
      <div class="certbox" ref="certificate">
        <img class="certimg" style="border-radius:10px;display:block" src="../../public/image/certificate.jpg" alt="" />
        <div class="cert_content" style="width: 80%">
          <div style="color:#605F5F;font-size:20px;font-weight:bold" class="name">{{ showdata.name }}</div>
          <div style="margin-top:20px;color:#605F5F">感谢您参与华东理工大学商学院</div>
          <div style="color:#605F5F">商小志公益系列活动，作为志愿者在</div>
          <div style="margin-top:15px" class="activityName">{{ showdata.activityName }}</div>
          <div style="margin-top:20px;color:#E7851D;font-size:20px;font-weight:bold" class="time">公益时长{{ showdata.time }}小时</div>
        </div>
      </div>
      <div style="display:flex;justify-content:center;margin-top: 20px">
        <el-button type="primary" @click='downPoster'>下载证书</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import html2canvas from 'html2canvas'
export default {
  name: "ClubUser",
  data() {
    return {
      clubid: "",
      tableData: [],
      totalNum: 0,
      pageData: {
        page: 1,
        limit: 10,
        search: "",
      },
      data: [],
      dialogVisible: false,
      showcert: false,
      showdata: {
        name: "",
        activityName: "",
        time: "",
      },
      isshoweditbtn: true
    };
  },
  created() {
    this.clubid = this.$route.query.id;
    this.pageData.id = this.$route.query.id;
    this.getData();
  },
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('人员编辑')) == -1) {
      this.isshoweditbtn = false
    }
  },
  methods: {
    // 控制页面的切换
    handleCurrentChange: function (page) {
      this.pageData.page = page;
      this.getData();
    },

    // 清除page重载列表
    clearPageGetData(){
      this.pageData.page = 1;
      this.getData();
    },

    getData() {
      this.$axios({
        url: "/backend/club_user_list/",
        methods: "get",
        params: this.pageData,
      })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data;
            this.totalNum = res.data.total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 公益时长
    userTime(id) {
      this.$axios({
        url: "/web/certificate_list/",
        method: "get",
        params: {
          id: id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.dialogVisible = true;
            this.data = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 查看证书
    checkCertf(e) {
      let name = e.name;
      let activityName = e.activityName;
      let time = e.time;
      this.showdata = {
        name: name,
        activityName: activityName,
        time: time,
      };
      this.showcert = true;
    },

    // 下载证书
    downPoster() {
      const canvas = document.createElement("canvas");
      // 获取父标签，意思是这个标签内的 DOM 元素生成图片
      let canvasBox = this.$refs.certificate;
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";

      const context = canvas.getContext("2d");
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then(canvas => {
        let dataURL = canvas.toDataURL("image/png");
        this.downloadImage(dataURL);
      });
    },

    downloadImage(url) {
      let a = document.createElement("a");
      a.href = url;
      a.download = "文件名";
      a.click();
    },

    // 人员详情
    userInfo(id) {
      // 没有出现此权限返回-1  进入详情
      // if(String(localStorage.getItem('perlist').indexOf('人员编辑')) == -1) {
      //   this.$message.error('您没有此权限')
      //   return false
      // }
      this.$router.push({
        path: "/club/clubuserinfo",
        query: {
          id: id,
          clubid: this.clubid,
        },
      });
    },

    // 删除
    handleDelete(e, row) {
      let userid = row.id;
      let user_ids = [];
      user_ids.push(userid);

      this.$confirm("是否删除此人员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: "/backend/delete_club_user/",
            method: "get",
            params: {
              user_ids: JSON.stringify(user_ids),
              club_id: this.clubid,
            },
          })
            .then((res) => {
              if (res.data.success) {
                this.$message.success("删除成功");
                this.getData();
              } else {
                this.$message.error(res.data.error);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  computed: {
    ...mapState(["imgurl"]),
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

// 搜索框
.search_box {
  margin-top: 40px;
  width: 25%;
  display: flex;
  align-items: center;
}

.certbox {
  position: relative;
  .cert_content {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// 证书
.certimg {
  width: 100%;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>
