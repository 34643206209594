<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/adminhome' }"
            >首页管理</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >新增精选推荐</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="data-info">
        <div class="info-operate">
          <el-button type="primary" @click="submitUpload">保存</el-button>
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
        <el-card style="margin-top: 20px">
          <div
            style="
              font-size: 20px;
              font-weight: 700;
              color: #2658ea;
              text-align: left;
            "
          >
            新增精选推荐
          </div>
          <el-row :gutter="24" style="margin-top: 20px">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">标题</div>
                <el-input v-model="title"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">类型</div>
                <el-select
                  style="width: 100%"
                  v-model="value"
                  placeholder="请选择"
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">需求/活动/文章</div>
                <el-select
                  style="width: 100%"
                  v-model="acvalue"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in acoptions"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">排序</div>
                <el-input v-model="sort"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">是否上线</div>
                <el-select
                  style="width: 100%"
                  v-model="onlinevalue"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in onlinelist"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddSelectRecd",
  components: {
    
  },
  data() {
    return {
      data: {},
      // 类型
      options: [
        {
          type: 0,
          name: '需求'
        },
        // {
        //   type: 1,
        //   name: '品牌活动'
        // },
        {
          type: 2,
          name: '社团活动'
        },
        // {
        //   type: 3,
        //   name: '班级活动'
        // },
        {
          type: 4,
          name: '社团文章'
        },
        // {
        //   type: 5,
        //   name: '班级文章'
        // },
        // {
        //   type: 6,
        //   name: '新生破冰'
        // },
        // {
        //   type: 7,
        //   name: '其他'
        // },
      ],
      // 活动列
      acoptions: [],
      // 是否上线
      onlinelist: [
        {
          type: 1,
          name: '否'
        },
        {
          type: 0,
          name: '是'
        }
      ],
      sort: '',
      title: "",
      // 选择的类型
      value: "",
      // 选择的活动
      acvalue: '',
      // 选择是否上下线
      onlinevalue: '',
      // 本地图片地址
      imageUrl: "",
      severurl: "", // 服务器图片地址
      dialogVisible: false,
      formDate: "",
      uploadDisabled: false,
      // 开始时间至结束时间
      timevalue: [],
    };
  },
  created() {
  },
  mounted() {},
  methods: {
     // 类型选择
    selectChange(e) {
      this.acoptions = []
      this.acvalue = ''
      this.requestData(e)
    },

    requestData(type) {
      this.$axios({
        url: "/backend/search_type/",
        methods: "get",
        params: {
          type: type,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.acoptions = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },


    // 保存 新增Banner
    submitUpload() {
      let formdata = new FormData
      // 手动上传 文件列表
      if (this.value === '') {
        this.$message.error("请选择类型");
        return false;
      }
      if(this.acvalue === '') {
        this.$message.error("请选择活动/文章");
        return false;
      }
      formdata.set("type", this.value);
      formdata.set("title", this.title);
      formdata.set("sort", this.sort);
      formdata.set('is_delete', this.onlinevalue)
      formdata.set("relate_id", this.acvalue);
      this.$axios({
        url: "/backend/recommended_create/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("保存成功");
            this.$router.push({
              path: "/adminhome/",
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.isshowimg /deep/ .el-upload {
  display: none;
}

// 背景框大小
/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  margin: 0 10px 0 0;
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.info-breadcrumb{
  margin-top: 10px;
}

.data-info {
  margin-top: 30px;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-card {
  // height: 70vh;
}

.el-row {
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.el-col-6 {
  margin-bottom: 20px;
}

.bg-purple {
  background: #fff;
  border: 1px solid #c9c9c9;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

// .el-input /deep/ .el-input__inner {
//   border: none;
// }

.el-input.is-disabled /deep/ .el-input__inner {
  background: #fff;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>