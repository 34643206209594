<template>
  <div class="information">
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb-text" :to="{ path: '/index/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club?id=' + this.clubid }"
            >社团</el-breadcrumb-item
          >
          <el-breadcrumb-item
            class="breadcrumb-text"
            :to="{ path: '/club/part?id=' + this.clubid }"
            >部门</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb-text"
            >部门信息</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="data-info">
        <div class="info-operate">
          <el-button type="primary" v-if="isshoweditbtn" @click="UpdateUser">保存</el-button>
          <el-button type="danger">删除</el-button>
        </div>
        <el-card style="margin-top: 20px">
          <div
            style="
              font-size: 20px;
              font-weight: 700;
              color: #2658ea;
              text-align: left;
            "
          >
            部门信息
          </div>
          <el-row :gutter="6" style="margin-top: 20px">
            <el-col :span="6">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <div style="margin-bottom: 10px">部门名称</div>
                <el-input v-model="data.department_name"></el-input>
              </div>
            </el-col>
          </el-row>
          <div class="per-container" v-if="permissionData.length > 0">
            <div>部门权限</div>
            <div class="choose_box">
              <div>
                <el-checkbox
                  @change="allCheck"
                  v-model="isallCheck"
                  :indeterminate="isIndeterminate"
                  label="全选"
                ></el-checkbox>
              </div>
              <!-- <div style="margin-left: 10px">
                <el-checkbox @change="noallCheck" label="非全选"></el-checkbox>
              </div> -->
            </div>

            <div v-for="(item, index) in permissionData" :key="index">
              <el-checkbox
                style="margin-top: 20px; display: flex; align-items: flex-start"
                :indeterminate="item.isIndeterminate"
                v-model="item.isCheck"
                @change="handleCheckAllChange(item.isCheck, index)"
                >{{ item.name }}
              </el-checkbox>
              <div style="margin: 15px 0"></div>
              <el-checkbox-group
                v-model="item.checkedData"
                @change="handleCheckedCitiesChange(item.checkedData, index)"
              >
                <el-checkbox
                  v-for="(pitem, index) in item.perlist"
                  :label="pitem.id"
                  :key="index"
                >
                  {{ pitem.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartInfo",
  data() {
    return {
      data: {},
      userid: "",
      name: "",
      grade: "",
      classname: "",
      options: [],
      value: "",
      // 标签列表
      dynamicTags: [],
      inputVisible: false,
      // 输入的标签
      inputValue: "",
      checkAll: false,
      permissionData: [], // 权限
      isallCheck: false, // 最外层全选
      isIndeterminate: false, // 最外层全选
      isshoweditbtn: true
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.clubid = this.$route.query.clubid;
    this.requestData();
    this.getPermission()
  },
  mounted() {
    if(String(localStorage.getItem('perlist').indexOf('部门编辑')) == -1) {
      this.isshoweditbtn = false
    }
  },
  methods: {
    requestData() {
      this.$axios({
        // url: "https://lihuashu.gazrey.com/backend/get_club_user/",
        url: "/backend/get_department/",
        methods: "get",
        params: {
          department_id: this.id,
          club_id: this.clubid,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    getPermission() {
      this.$axios({
        // url: "https://lihuashu.gazrey.com/backend/get_club_user/",
        url: "/backend/get_department_permission/",
        methods: "get",
        params: {
          clubid: this.clubid,
          department_id: this.id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            if (res.data.data && res.data.data.length > 0) {
              for (var i = 0; i < res.data.data.length; i++) {
                let checkedData = [];
                this.$set(res.data.data[i], "isCheck", false);
                this.$set(res.data.data[i], "isIndeterminate", true);
                let have = 0;
                for (var j = 0; j < res.data.data[i].perlist.length; j++) {
                  if (res.data.data[i].perlist[j].is_have) {
                    have++;
                    checkedData.push(res.data.data[i].perlist[j].id);
                  }
                }
                if (parseInt(have) == res.data.data[i].perlist.length) {
                  this.$set(res.data.data[i], "isCheck", true);
                  this.$set(res.data.data[i], "isIndeterminate", false);
                  this.isallCheck = true;
                  this.isIndeterminate = false;
                } else {
                  this.isallCheck = false;
                  this.$set(res.data.data[i], "isIndeterminate", false);
                }
                this.$set(res.data.data[i], "checkedData", checkedData);
              }
            }
            this.permissionData = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // all 全选
    allCheck(val) {
      let permissionData = this.permissionData;
      if (val) {
        for (var i = 0; i < permissionData.length; i++) {
          // permissionData[i].isCheck = true
          this.$set(permissionData[i], "isCheck", true);
          let arr = [];
          for (let j = 0; j < permissionData[i].perlist.length; j++) {
            arr[j] = permissionData[i].perlist[j].id;
          }
          this.$set(permissionData[i], "checkedData", arr);
          this.$set(permissionData[i], "isIndeterminate", false);
        }
      } else {
        for (var i = 0; i < permissionData.length; i++) {
          // permissionData[i].isCheck = true
          this.$set(permissionData[i], "isCheck", false);
          let arr = [];
          this.$set(permissionData[i], "checkedData", arr);
          this.$set(permissionData[i], "isIndeterminate", false);
        }
      }
      this.permissionData = permissionData;
    },

    handleCheckAllChange(val, index) {
      // 全选 val 是否全选
      let arr = [];
      const re = this.permissionData[index].perlist;
      //全选
      if (val) {
        for (let i = 0; i < re.length; i++) {
          arr[i] = re[i]["id"];
        }
      }
      // this.permissionData[index].checkedData = arr;
      // this.permissionData[index].isIndeterminate = false;
      this.$set(this.permissionData[index], "checkedData", arr);
      this.$set(this.permissionData[index], "isIndeterminate", false);

      let permissionData = this.permissionData;
      let have = 0;
      for (var i = 0; i < permissionData.length; i++) {
        if (permissionData[i].isCheck) {
          have++;
        }
      }
      if (parseInt(have) == permissionData.length) {
        this.isallCheck = true;
        this.isIndeterminate = false;
      } else {
        this.isallCheck = false;
        this.isIndeterminate = true;
      }
    },

    handleCheckedCitiesChange(val, index) {
      let checkedCount = val.length;
      // this.permissionData[index].isCheck =
      //   checkedCount === this.permissionData[index].perlist.length;

      // this.permissionData[index].isIndeterminate =
      //   checkedCount > 0 && checkedCount < this.permissionData[index].perlist.length;

      this.$set(
        this.permissionData[index],
        "isCheck",
        checkedCount === this.permissionData[index].perlist.length
      );
      this.$set(
        this.permissionData[index],
        "isIndeterminate",
        checkedCount > 0 &&
          checkedCount < this.permissionData[index].perlist.length
      );
      let permissionData = this.permissionData;
      let have = 0;
      for (var i = 0; i < permissionData.length; i++) {
        if (permissionData[i].isCheck) {
          have++;
        }
      }
      if (parseInt(have) == permissionData.length) {
        this.isallCheck = true;
        this.isIndeterminate = false;
      } else {
        this.isallCheck = false;
        this.isIndeterminate = true;
      }
    },

    // 保存
    UpdateUser() {
      if (!this.data.department_name) {
        this.$message.error("请输入部门名称");
        return false;
      }
      let permissionData = this.permissionData;
      let perIds = [];
      for (let i = 0; i < permissionData.length; i++) {
        if (permissionData[i].isCheck) {
          perIds.push(permissionData[i].id);
          for (let j = 0; j < permissionData[i].checkedData.length; j++) {
            perIds.push(permissionData[i].checkedData[j]);
          }
        } else {
          for (let j = 0; j < permissionData[i].checkedData.length; j++) {
            perIds.push(permissionData[i].checkedData[j]);
          }
          if (permissionData[i].checkedData.length > 0) {
            perIds.push(permissionData[i].id);
          }
        }
      }
      let formdata = new FormData();
      formdata.set("department_id", this.id);
      formdata.set("department_name", this.data.department_name);
      formdata.set("club_id", this.clubid);
      formdata.set("perIds", JSON.stringify(perIds));
      this.$axios({
        url: "/backend/change_department/",
        method: "post",
        data: formdata,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("保存成功");
            this.$router.push({
              path: "/club/part",
              query: {
                id: this.clubid,
              },
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  background: url("/login/bg2.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  .info-container {
    margin: 0 auto;
    width: 75vw;
    padding: 10vh 0;
    box-sizing: border-box;

    .info-tab {
      margin-top: 40px;
    }
    .info-table {
      margin-top: 30px;
    }
  }
}

.breadcrumb-text /deep/ .el-breadcrumb__inner {
  color: #000000;
  font-size: 16px;
  background: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.data-info {
  margin-top: 30px;
}

.info-operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-card {
  // height: 70vh;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.el-col-6 {
  margin-bottom: 20px;
}

.bg-purple {
  background: #fff;
  border: 1px solid #c9c9c9;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

// .el-input /deep/ .el-input__inner {
//   border: none;
// }

.el-input.is-disabled /deep/ .el-input__inner {
  background: #fff;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.choose_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.tab-self /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.tab-self .el-tabs__item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.el-table--enable-row-transition {
  border-radius: 5px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #2658ea;
}

.tab-self .el-tabs__item {
  border-bottom: 1px solid #e4e7ed !important;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tab-self .el-tabs__item:hover {
  // color: #2658EA;
  color: #fff;
}

.tab-self .el-tabs__item.is-active {
  color: #fff;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: none !important;
}

// 底部分页
.info-pagination {
  margin-top: 40px;
}

// 分页默认样式
.el-pagination {
  display: flex;
  padding: unset !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:first-child {
  margin-left: unset !important;
}
</style>